import React from "react";
import styled from "styled-components";
import { Radio } from "../atoms";
import { Flex } from 'rebass';

const RadioGroup = styled(Flex)`
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${p => p.flexWrap || 'wrap' };
`;

const RadioButtonGroup = ({disabled, onChange, list, selectedIndex, testid, justifyContent, minWidth, minHeight, size, showIcon, addTag, tagFontSize, left, ...props }) => {
  return (
    <RadioGroup justifyContent={justifyContent} flexWrap={props.flexWrap}>
      {list.map((item, index) => (
        <Radio
          key={index}
          onChange={() => !item.disabled && onChange(index)}
          checked={index === selectedIndex}
          borderColor={props.borderColor}
          borderWidth={props.borderWidth}
          component={item.component}
          width={props.width}
          br={props.br}
          testid={`${testid}-${index}`}
          mr={props.mr}
          mt={props.mt}
          pt={props.pt}
          left={left}
          tagFontSize={tagFontSize}
          minWidth={minWidth}
          minHeight={minHeight}
          padding={props.padding}
          checkedColor={props.checkedColor}
          defaultColor={props.defaultColor}
          color={props.color}
          defaultBgColor={props.defaultBgColor}
          activeBgColor={props.activeBgColor}
          disabled={disabled}
          fontSize={props.fontSize}
          index={index}
          addTag={addTag}
          size={size}
          showIcon={showIcon}
          radioIcons={props.radioIcons}
        >
          {item.title}
        </Radio>
      ))}
    </RadioGroup>
  );
};

RadioButtonGroup.defaultProps = {
  selectedIndex: 0,
  justifyContent: 'start',
  testid: 'radio',
  padding: '9px 7px',
  mr: '5px',
  borderColor: 'lightGrey'

}

export default RadioButtonGroup;
