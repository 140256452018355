const addNewFamilyMemberReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
  case 'SET_BENEFIT_CONFIG_DETAILS':
    return { ...state, benefitConfigDetails: {...payload} };
  case 'SET_SELECTED_PACKAGE':
    return { ...state, selectedPackage: {...payload} };
  case 'SET_SELECTED_MEMBER':
    return { ...state, seletedMembers: payload };
  case 'SET_FROM_BENEFIT_DRAWER':
    return { ...state, isFromBenefitDrawer: payload };
  case 'SET_DOCTOR_CONSULTATION_DATA':
    return { ...state, doctorConsultationData: payload };
  case 'SET_LAB_TEST_DATA':
    return { ...state, labTestDataFromAddFamilyMember: payload };
  default:
    return state;
  }
}

export default addNewFamilyMemberReducer;