import styled from 'styled-components'
import { Box } from 'rebass';

const SectionHeading = styled(Box).attrs(() => ({as: 'h6'}))`
  margin-bottom: ${props => props.mb || props.my || '0.35em'};
  color: ${props => props.theme.colors.title};
  font-size:  ${props => props.fontSize || '1.25rem'};
  font-family: ${({ bold, theme: { fonts }}) => {
  if(bold) {
    return fonts.semiBold
  } else {
    return fonts.extraBold
  }
}};
  line-height: 1.75;
  letter-spacing: 0.00938em;
  overflow-x: hidden;
  margin-left: ${props => props.ml || props.mx || 0};
  margin-right: ${props => props.mr || props.mx || 0};
  margin-top: ${props => props.mt || props.my || 0};
  padding-bottom: ${props => props.pb || props.py || '0.35em'};
  padding-left: ${props => props.pl || props.px || 0};
  padding-right: ${props => props.pr || props.px || 0};
  padding-top: ${props => props.pt || props.py || 0};
  display: block;
  text-align: ${props => props.alignSelf || ''};
  background: ${props => props.bg || ''};
`

export default SectionHeading;