import React, { Suspense, lazy } from 'react';
import { useHistory } from 'react-router-dom';
import MobileOnlyFeatureModal from '../modals/MobileOnlyFeatureModal';
import UploadMedicalRecord from '../modals/UploadMedicalRecord';
import ChatEnquiryNow from '../modals/ChatEnquiryNow';
import { useUserDetails } from '../../../utils/customHooks'
import PointsEarnedModal from '../rewards/PointsEarnedModal'
const EditProfileModal = lazy(() => import('../../molecules/EditProfileModal'))

const LayoutModalsConsumer = ({
  showModal, showMobileOnlyModal,
  showChatEnquiryNow, fdEnquiryNowData,
  closeModal, updateModal,
  showEditProfileModal, showPointsEarnedModal,
  ...data
}) => {
  const history = useHistory()
  const { user } = useUserDetails()

  if(showModal) {
    return <UploadMedicalRecord customer={data.customer} type={data.type} history={history} showTypeSelection={data.showTypeSelection} showMemberSelection={data.showMemberSelection} />;
  } else if(showMobileOnlyModal) {
    return <MobileOnlyFeatureModal closeModal={closeModal} />
  } else if(showChatEnquiryNow) {
    return <ChatEnquiryNow
      onCloseModal={() => updateModal({showChatEnquiryNow: false})}
      fdEnquiryNowData={fdEnquiryNowData}
    />
  } else if(showEditProfileModal){
    return(
      <Suspense fallback={'Loading'}>
        <EditProfileModal customer={user} onCloseModal={() => updateModal({showEditProfileModal: false})} isMobileOtp={data.isMobileOtp} mobileNumber={data.mobileNumber} />
      </Suspense>
    )
  } else if (showPointsEarnedModal) {
    return <PointsEarnedModal data={data.pointsDetails} onCloseModal={() => updateModal({showPointsEarnedModal: false})}/>
  }
  else {
    return null;
  }
}

export default LayoutModalsConsumer;