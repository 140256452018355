import React from 'react';
import Modal from './Modal'
import { Image, CardTitle} from '../atoms';
import paymentLoader from '../../assets/images/payment-loader.svg';

const PaymentLoader = () => {

  return(
    <Modal size='xxs' closeOnClickOverlay={false} closeIcon={false} >
      <div className='flex justify-center items-center px-5 py-5 w-full border border-gray-200 rounded-lg bg-white'>
        <div className='flex flex-col items-center'>
          <Image src={paymentLoader} height='100px' />
          <CardTitle>Don't refresh or close</CardTitle>
        </div>
      </div>
    </Modal>
  )
}

export default PaymentLoader;
