import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const Image = styled(Box).attrs(() => ({as: 'img'}))`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  ${({ minWidth }) => {
  return minWidth && css`
      min-width: ${minWidth};
    `
}}
${({ maxWidth }) => {
  return maxWidth && css`
      max-width: ${maxWidth};
    `
}}
${({ maxHeight }) => {
  return maxHeight && css`
      max-height: ${maxHeight};
    `
}}
  ${({ borderRadius }) => {
  return borderRadius && css`
      border-radius: ${borderRadius};
    `
}}
  ${({ pointer }) => {
  return pointer && css`
      cursor: pointer;
    `
}}


`

export default Image;