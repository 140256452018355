import React, { useEffect, useCallback, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { publicRoutes, PRIVATE_ROUTES } from './routes';
import Layout from './pages/Layout';
import { withContextHOC, withTrackerHOC } from './utils/hocs';
import NotFoundPage from './pages/errors/NotFoundPage';
import tracker from './utils/tracker';
import { queryParams } from './utils/common';
import MobileWebPwaContext from './utils/contexts/MobileWebPwaContext';

const App = () => {
  const [showMobileWebPwaBanner, ToggleShowMobileWebPwaBanner] = useState(true)

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof window.Moengage !== undefined) {
      trackEvent()
    }
  }, [])

  const trackEvent = useCallback(() => {
    const params = queryParams(window.location.search)
    const moengageEvents = {}
    for (const [key, value] of Object.entries(params)) {
      if (key.startsWith('utm')) {
        moengageEvents[key] = value
      }
    }
    if (Object.keys(moengageEvents).length > 0) {
      tracker.track('utm_campaign', moengageEvents)
    }
  })
  return (
    <div className='flex flex-col box-border max-h-screen max-w-full'>
      <MobileWebPwaContext.Provider value={[showMobileWebPwaBanner, ToggleShowMobileWebPwaBanner]}>
        <Switch>
          {publicRoutes(showMobileWebPwaBanner)}
          <Route path={PRIVATE_ROUTES.reduce((acc, { path }) => acc.concat(path), [])} component={Layout} exact={true} />{/* ets a navbar, bottom and placeholder for signed in users.*/}
          <Route component={NotFoundPage} />{/* Renders when no routes are found. */}
        </Switch>
      </MobileWebPwaContext.Provider>
    </div>
  )
}

export default withTrackerHOC(withContextHOC(App));
