import React from 'react';
import styled, {css} from 'styled-components';
import {Flex} from 'rebass'
import { Icon } from '../atoms';

const IconWithTextContainer = styled(Flex)`
    flex-direction: ${({ flexDirection }) => flexDirection };
    align-items: ${({ alignItems }) => alignItems};
    ${({justifyContent}) => justifyContent && css`justify-content: ${justifyContent}`};
    ${p => p.borderRadius && css`border-radius: ${p.borderRadius}`};
    ${p => p.bgColor && css `background-color: ${p.theme.colors[p.bgColor]}`};
    ${p => p.borderColor && css `border: 1px solid ${p.theme.colors[p.borderColor]} `};
    ${p => p.borderWidth && css `border-width: ${p.borderWidth}`};
`

IconWithTextContainer.defaultProps = {
  flexDirection: 'column',
  alignItems: 'center'
}
const IconWithText = ({ children, src, size, width, flex, mr, ml, mt, mb, alignIconEnd, rotate, pointer, ...props}) => (
  <IconWithTextContainer width={width} flex={flex} {...props}>
    {!alignIconEnd && <Icon src={src} size={size} mr={mr} ml={ml} mt={mt} mb={mb} rotate={rotate} pointer={pointer}/>}
    {children}
    {alignIconEnd && <Icon src={src} size={size} ml={mr} mt={mt} mb={mb} borderRadius={props.borderRadius} rotate={rotate} pointer={pointer}/>}

  </IconWithTextContainer>
)

IconWithText.defaultProps = {
  size: 'md',
  alignIconEnd: false
}

export default IconWithText;