import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const ListElement = styled(Box).attrs(({ theme: { fontSizes, space }, size = 'md' }) => {
  let configure = {}
  if(size === 'xs') {
    configure = {
      fontSize: `${fontSizes[size]}`,
      padding: `${space[1]/16}em ${space[0]/16}em`
    }
  } else if (size === 'sm') {
    configure = {
      fontSize: `${fontSizes[size]}`,
      padding: `${space[2]/16}em ${space[1]/16}em`
    }
  } else if (size === 'md') {
    configure = {
      fontSize: `${fontSizes[size]}`,
      padding: `${space[3]/16}em ${space[2]/16}em`
    }
  } else if (size === 'lg') {
    configure = {
      fontSize: `${fontSizes[size]}`,
      padding: `${space[4]/16}em ${space[3]/16}em`
    }
  } else if (size === 'xl') {
    configure = {
      fontSize: `${fontSizes[size]}`,
      padding: `${space[5]/16}em ${space[4]/16}em`
    }
  }
  return {
    ...configure,
    as: 'li'
  }
})`
    font-size: ${({ fontSize }) => fontSize};
    padding: ${({ padding }) => padding};
    cursor: ${({cursor}) => cursor ? cursor : 'pointer'};
    color: ${({ theme: { colors }, color }) => color ? colors[color] : colors['subtitle']};
    white-space: ${props => props.whiteSpace};
    &:disabled {
        cursor: unset;
    }
    &:hover {
        color: ${({ theme: { colors }, hover}) => !hover && colors.primary};
    }
    ${({ active }) => {
  return active && css`
            background-color: #f4f5f7;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            h2{
              color: ${({ theme: { colors }}) => colors.primary};
            }
        `
}}
`

export default ListElement;