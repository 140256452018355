import styled from 'styled-components';
import { media } from '../../utils/mediaBreakpoints';

/**
 * As its skelton for navbar without any other child elements.
 * And it is the minimal design to start for navbar comes in atom folder.
 */
const Navbar = styled.div`
  display: flex;
  max-width: 100%;
  height: 60px;
  min-height: 60px;
  z-index: 2; /* Added for dashboard banner to align down this element.*/
	justify-content: space-between;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  width:100%;
  background-color: white;
  ${media.mobile`
    display: none;
  `}
`

export default Navbar;