import React from 'react';
import ContentLoader from "react-content-loader";

const ListLoader = () => (
  <ContentLoader 
    height={110}
    width={600}
    speed={2}
    primaryColor="#ebedf0"
    secondaryColor="#c1bebe"
  >
    <rect x="25" y="15" rx="5" ry="5" width="329" height="10" /> 
    <rect x="24" y="44" rx="5" ry="5" width="192" height="10" /> 
    <rect x="25" y="74" rx="5" ry="5" width="113" height="12" /> 
  </ContentLoader>
)

export default ListLoader;
