import styled, {css} from 'styled-components';
import { media } from '../../utils/mediaBreakpoints';
import { Box } from 'rebass';

const Main = styled(Box)`
  display: block;
  width: 80%;
  position: relative;
  ${media.mobile`
    width: 100%;
    ${({hideMenu}) => !hideMenu && css`
      margin-top: 60px;
      bottom: 60px;
    `}
    display: flex;
    justify-content: center;
    flex-direction: column;
  `}
  ${media.tablet`
    width: 80%;
    margin: 40px auto 10px;
  `}
  ${media.desktop`
    margin: 40px auto 10px;
    width: 940px;
  `}
`

export default Main;