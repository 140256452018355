import React from 'react';
import { Flex } from 'rebass';
import { android, ios } from '../../assets/icons';
import { Link,Image} from '../atoms';

const AppDownloadButtonsGroup = () => {
  
  return(
    <Flex flexDirection={['column', 'row']} justifyContent="center" alignItems={['center', 'unset']} px={[4, 0]} backgroundColor={['white', 'unset']}>
      <Link variant='primary' mx={3} href="https://play.google.com/store/apps/details?id=com.ekincare" target="_blank">
        <Image src={android} width='145px'/>
      </Link>
      <Link variant='primary' mx={3} href="https://itunes.apple.com/us/app/ekincare/id973037249?ls=1&mt=8" target="_blank">
        <Image src={ios} pt={[4,0]} width='145px'/>
      </Link>	
    </Flex>
  )
}
export default AppDownloadButtonsGroup;