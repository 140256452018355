import styled, { css } from 'styled-components';
import { Flex } from 'rebass';

const Line = styled(Flex)`
	color: ${props => props.color ? props.theme.colors[props.color] : props.theme.borders.default};
  border-bottom: 1px ${props => props.borderType ? props.borderType : 'solid'} ${props => props.color ? props.theme.colors[props.color] : props.theme.borders.default};
  margin-top:  ${props => props.mt};
  margin-bottom:  ${props => props.mb};
  margin-left:  ${({ ml }) => ml ? ml : 0};
  margin-right:  ${({ mr }) => mr ? mr : 0};
  ${({ rotate }) => rotate && css`transform: rotate(${rotate}deg)`};
`

Line.defaultProps = {
  mb: 4,
  mt: 4
}

export default Line;