import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { CardTitle, Icon, CardText } from '../atoms';
import { media, excludeMedia } from '../../utils/mediaBreakpoints';
import hint from '../../assets/icons/hint.svg';
import { directionRight } from '../../assets/icons';

const Container = styled(Flex)`
  width: 347px;
  padding-right: 12px;
  ${media.mobile`
    width: 100%;
    border-radius: 10px;
  `}
  ${excludeMedia.mobile`
    min-height: 55px;
    border-radius: 10px;
  `}
  background-color: ${({ theme: { colors }}) => colors.lightestGrey};
`

const IconContainer = styled(Flex)`
  flex-direction: column;
  padding: 15px 0 10px 20px;
  ${media.mobile`
    padding: 15px 0 10px 10px;
  `}
`

const TextContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 16px 15px 16px 16px;
  height: '50px';
`

const Chevron = styled(Box)`
  ${excludeMedia.mobile`
    display: none;
  `}
  ${media.mobile`
    align-self: flex-end;
    margin: auto;
  `}
`

const CardTextFooter = styled(CardText)`
  ${media.mobile`
    display: none;
  `}
`

/**
  Footer is not visible on mobile view.
 */
const InstructionsCard = ({ icon, header, body, footer, navigator, className, onClick, showNavigator=true }) => (
  <Container className={className} onClick={onClick}>
    {icon && <IconContainer>
      {React.isValidElement(icon) ? icon : (icon && typeof(icon) === 'object' ? <Icon size={icon.size || 'md'} height={'42px'} width={'42px'} src={icon.src}/> : null)}
    </IconContainer>}
    <TextContainer>
      {React.isValidElement(header) ? header : <CardTitle color='extraInformation' pb='0' mb='4px' lineHeight='1' bold>{header}</CardTitle>}
      {React.isValidElement(body) ? body : (body ? <CardText color='lightBlue' pb='0em'>{body}</CardText> : null)}
      {React.isValidElement(footer) ? footer : (footer ? <CardTextFooter py='1em'>{footer}</CardTextFooter> : null)}
    </TextContainer>
    {showNavigator ? React.isValidElement(navigator) ? navigator : (navigator && typeof(navigator) === 'object' ? <Icon size='md' src={navigator.src}/> : null) : null}
  </Container>
)

InstructionsCard.defaultProps = {
  icon: <Icon size='xxl' src={hint}/>,
  navigator: <Chevron><Icon src={directionRight} size='sm' mr={'10px'}/></Chevron>
}

export default InstructionsCard;