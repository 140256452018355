import React from 'react';
import styled from 'styled-components';
import {Flex, Box} from 'rebass'
import { CardTitle, CardText} from './typography';
import Icon from './Icon';

const AlertWrapper = styled(Box)`
  background-color: ${props => ({theme: {alerts}}) => alerts[props.type]};
  padding: 0.75em;
  margin: 1em 0;
  font-size: ${props => props.fontSize + 'px'};
`

const FormAlert = ({icon={}, type, display, title, subtitle, fontSize='14px'}) => {
  return (
    <AlertWrapper type={type} display={display} fontSize={fontSize} title={title} >
      <Flex>
        {icon.src && <Icon src={icon.src} size={icon.size} />}
        {title && <CardTitle ml='5px' mb='5px' pb='0px'>{title}</CardTitle>}
      </Flex>
      {subtitle && <CardText>{subtitle}</CardText>}
    </AlertWrapper>
  )
}
  
export default FormAlert;