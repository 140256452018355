import styled, { css } from 'styled-components';
import { Box } from 'rebass';

/**
 * Icon takes in url parameter which are the relative paths from assets/icons folder.
 * size picks up values from themes/index.js `iconSizes`
 */
const Icon = styled(Box).attrs(({
  rotate, pointer, width, height, borderRadius
}) => ({as: 'img', rotate, pointer, width, height, borderRadius}))`
    ${({ theme: { iconSizes }, size = 'sm' }) => {
  return css`
            width: ${iconSizes[size]};
            height: ${iconSizes[size]};
            ${({ width }) => width && css`width: ${width}`};
            ${({ height }) => height && css`height: ${height}`}; 
            background-size: cover;
            vertical-align: ${({verticalAlign}) => verticalAlign || 'middle'};
            ${({ maxWidth }) => maxWidth && css`max-width: ${maxWidth};`}
            ${({ maxHeight }) => maxHeight && css`max-height: ${maxHeight};`}
            ${({ rounded }) => rounded && css`border-radius: 50%`};
            ${({ rotate }) => rotate && css`transform: rotate(${rotate}deg)`};
            ${({ ml }) => ml && css`margin-left: ${ml}`};
            ${({ mt }) => mt && css`margin-top: ${mt}`};
            ${({ mr }) => mr && css`margin-right: ${mr}`};
            ${({ mb }) => mb && css`margin-bottom: ${mb}`};
            ${({ pointer }) => pointer && css`cursor: pointer`};
            ${({ borderRadius }) => borderRadius && css`border-radius: ${borderRadius}`};
            opacity: ${props => props.opacity};
        `
}}
`

export default Icon;