import { useContext, useState, useEffect } from 'react';
import { UserDetailsContext } from '../contexts';
import NotificationsAPI from '../../services/notifications.api';

const useFetchNotifications = (refresh = false, longPoll) => {
  const { userDetails: { notifications, notificationsRecentCount }, updateUserNotifications } = useContext(UserDetailsContext)
  const [pageState, updatePageState] = useState({ isLoading: refresh, isError: false })
  useEffect(() => {
    if (pageState.isLoading) {
      (async () => {
        try {
          const response = await NotificationsAPI.fetchNotifications()
          updateUserNotifications({ notifications: response.data, count: response.data.recent && response.data.recent.total_count })
          updatePageState({
            ...pageState,
            isLoading: false
          })
        } catch (err) {
          updatePageState({
            ...pageState,
            isError: true,
            isLoading: false
          })
        }
      })()
    }
  }, [pageState.isLoading])

  const requireDataUpdation = () => {
    updatePageState({
      ...pageState,
      isLoading: true
    })
  }

  const checkUnreadNotifications = () => {
    return notifications?.recent?.notifications?.length > 0 ?? false;
  }

  useEffect(() => {
    if (refresh) {
      requireDataUpdation()
    }
  }, [refresh])

  const markAllViewed = () => {
    (async function () {
      try {
        if (notifications.recent && notifications.recent.notifications && notifications.recent.notifications.length) {
          await NotificationsAPI.markViewAll()
          updateUserNotifications({ notifications: notifications, count: 0 })
        }
      } catch (err) {
        // Not priority api. so silencing the error.
      }
    })()
  }

  const markNotificationAsRead = async (id) => {
    try {
      await NotificationsAPI.markNotificationAsRead({ id })
    } catch (err) {
      // Silencing the error.
    }
  }

  const markAllNotificationsAsRead = async () => {
    try {
      await NotificationsAPI.markAllNotificationsAsRead()
      requireDataUpdation();
    } catch (err) {
      // Silencing the error.
    }
  }

  useEffect(() => {
    let intervalId;
    if (longPoll) {
      intervalId = window.setInterval(requireDataUpdation, 120000)
    }
    return () => intervalId && window.clearInterval(intervalId)
  }, [longPoll])

  return {
    ...pageState, notifications, requireDataUpdation, markAllViewed,
    markNotificationAsRead, markAllNotificationsAsRead, checkUnreadNotifications, notificationsRecentCount
  }
}

export default useFetchNotifications;