import styled, {css} from 'styled-components'

const CardBody = styled.section`
	min-height: ${props => props.minHeight|| '450px'};
	${({px, pl}) => { return (px || pl) && css`padding-left: ${px || pl};`}}
	${({px, pr}) => { return (px || pr) && css`padding-right: ${px || pr};`}}
	${({mx, mr}) => { return (mx || mr) && css`margin-right: ${mx || mr};`}}
	${({mx, ml}) => { return (mx || ml) && css`margin-right: ${mx || ml};`}}
	${({my, mt}) => { return (my || mt) && css`margin-right: ${my || mt};`}}


`

export default CardBody;
