import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const CardTitle = styled(Box).attrs(({ block }) => ({as: 'h6', block}))`
  color: ${({ theme: { colors }, color, disabled }) => disabled? colors.darkGreyNew : (colors[color] || 'inherit')};
  font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize]};
  font-family: ${({ bolder, bold, theme: { fonts }}) => {
  if(bolder) {
    return fonts.extraBold
  } else if(bold) {
    return fonts.semiBold
  } else {
    return fonts.normal
  }
}};
  line-height: ${({ lineHeight }) => lineHeight || 1.75};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '0.00938em'};
  padding: 0px;
  display: ${({ block }) => block ? 'block' : 'inline'};
  text-align: ${props => props.alignSelf || props.textAlign || ''};
  margin: 0px;
  margin-bottom: ${props => props.mb || props.my || '0.35em'};
  margin-left: ${props => props.ml || props.mx || 0};
  margin-right: ${props => props.mr || props.mx || 0};
  margin-top: ${props => props.mt || props.my || 0};
  padding-bottom: ${props => props.pb || props.py || '0.35em'};
  padding-left: ${props => props.pl || props.px || 0};
  padding-right: ${props => props.pr || props.px || 0};
  padding-top: ${props => props.pt || props.py || 0};
  ${({ boxShadow }) => boxShadow && css`box-shadow: ${boxShadow};`}
  ${({ whiteSpace }) => whiteSpace && css`
    white-space: ${whiteSpace};
  `}
  ${({ cursor }) => cursor && css`cursor: ${cursor}`}
  ${({ width }) => width && css`
    width: ${width};
  `}
  ${({textOverflow}) => textOverflow && css`
    text-overflow: ${textOverflow};
  `};
  ${({overflow}) => overflow && css`
    overflow: ${overflow};
  `};
`

CardTitle.defaultProps = {
  block: true,
  color: 'title',
  textAlign: 'left'
}

export default CardTitle