import React, { useContext } from 'react'
import { Flex } from 'rebass'
import { Button, Icon } from '../atoms'
import { closeBlack } from '../../assets/icons'
import ekincareLogoWithoutText from '../../assets/icons/ekincare-logo-without-text.svg'
import yellowFilledStar from '../../assets/icons/yellow-filled-star.svg'
import { Text } from '../atoms/typography'
import styled from 'styled-components'
import { openAppStore } from '../../utils/common'
import MobileWebPwaContext from '../../utils/contexts/MobileWebPwaContext'

const ExtendedFlex = styled(Flex)`
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1000;
  padding: 16px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`

const MobileWebPwaBanner = () => {
  const [, ToggleShowMobileWebPwaBanner] = useContext(MobileWebPwaContext)

  const onCloseBanner = () => {
    ToggleShowMobileWebPwaBanner(false)
  }

  const RatingDownloadsText = () => (
    <Flex alignItems={'center'}>
      <Flex alignItems={'center'}>
        <Icon src={yellowFilledStar} size={'smm'} alt='rating star' mr={'4px'} />
        <Text color={'extraInformation'} fontSize={'11px'} lineHeight={'16px'} bold>4.6 / 5</Text>
      </Flex>
      <Flex alignItems={'center'}>
        <Text color={'steelBlue'} fontSize={'11px'} lineHeight={'16px'} bold>&nbsp;|&nbsp;</Text>
        <Text color={'steelBlue'} fontSize={'11px'} lineHeight={'16px'} bold>200K+ Downloads</Text>
      </Flex>
    </Flex>
  )

  return (
    <ExtendedFlex backgroundColor={'cardLightBlueBackground'} alignItems={'center'} justifyContent={'space-between'}>
      <Flex alignItems={'center'}>
        <Flex alignItems={'center'}>
          <Icon src={closeBlack} size={'s'} mr={'8px'} alt='close mark' onClick={onCloseBanner} />
          <Icon src={ekincareLogoWithoutText} size={'xxsl'} mr={'8px'} alt='ekincare logo' />
        </Flex>
        <Flex flexDirection={'column'} mr={'12px'}>
          <Text color={'extraInformation'} fontSize={'11px'} lineHeight={'14px'} mb={'4px'} bolder>ekincare is better on the app</Text>
          <RatingDownloadsText />
        </Flex>
      </Flex>
      <Button className={'truncate'} type={'default-outline'} size={'xs'} borderRadius='6px' onClick={openAppStore}>Download App</Button>
    </ExtendedFlex>
  )
}

export default MobileWebPwaBanner