import styled from 'styled-components'

const Sticker = styled.div`
	display: block;
	font-size: ${props => props.theme.fontSizes[props.fontSize] || props.theme.fontSizes['xs']};
	font-family: "ProximaNova-Semibold";
	border-radius: 25px;
	background-color: ${props => props.theme.stickers.background[props.color] || props.theme.colors.lightestGreen}; 
	padding: 5px 7px; 
	color: ${props => props.theme.stickers[props.color] || props.theme.stickers.green};
	text-align: center;
`

export default Sticker