import React from 'react';
import spinnerLoader from '../../assets/icons/spinner-loader.svg'

const SuspenseLoader = () => {
  return (
    <div className="flex justify-center items-center h-screen pb-16">
      <img src={spinnerLoader} width={'54px'} height={'54px'}/>
    </div>
  );
};

export default SuspenseLoader;