import styled from 'styled-components';
import { Box } from 'rebass';

const PageHeader = styled(Box).attrs(() => ({as: 'h3'}))`
	margin-bottom: 0.35em;
	color: ${props => props.theme.title};
  font-size: 3rem;
  font-family: ${({ theme: { fonts }}) => fonts.normal};
  line-height: 1.04;
  letter-spacing: 0em;
  margin: 0;
  display: block;
`

export default PageHeader