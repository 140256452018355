import React from 'react';

const TopMenu = ({children, onMouseLeave}) => {
  return(
    <div className='flex justify-between items-center py-0 lg:px-10 px-5' onMouseLeave={onMouseLeave}>
      {children}
    </div>
  )
}

export default TopMenu