const POST_LOGIN_REDIRECT = 'POST_LOGIN_REDIRECT';
const postLoginRedirect = {
  push: (route) => {
    window.localStorage.setItem(POST_LOGIN_REDIRECT, route)
  },
  pop: () => {
    const POST_LOGIN_REDIRECT_URL = window.localStorage.getItem(POST_LOGIN_REDIRECT)
    window.localStorage.removeItem(POST_LOGIN_REDIRECT);
    return POST_LOGIN_REDIRECT_URL;
  }
}

export default postLoginRedirect;