import React, { Fragment, useEffect, useReducer, memo } from 'react';
import { spring, TransitionMotion, presets } from 'react-motion';
import { Toast as ToastContainer } from '../atoms';
import { toastManager } from '../../utils/toast';
import Portals from '../../utils/portals';

// TODO:: bottom-left and bottom-right functionalities should be implemented.
const Toast = () => {

  const reducer = (state, action) => {
    const { type, ...data } = action;
    if(type === 'ADD') {
      if(state.filter(i => i.data.code && i.data.code !== data.code).length) {
        return state;
      }
      return [...state, data]
    } else if(type === 'REMOVE') {
      return state.filter(i => {
        return i.data.id !== action.id
      })
    }
    return state;
  }
  const [toastList, dispatchToastList] = useReducer(reducer, []);

  const callback = (actionType, content, options) => {
    if (actionType === 'ADD') {
      dispatchToastList({type: 'ADD', data: { content: content, ...options }, key: `${options.id}`});
    }
    if (options.pause && actionType === 'REMOVE') {
      dispatchToastList({type: 'REMOVE', id: options.id})
    } else if (!options.pause) {
      setTimeout(() => {
        dispatchToastList({type: 'REMOVE', id: options.id})
      }, options.timeout)
    }
  }

  useEffect(() => {
    toastManager.subscribe(callback);
  }, []);

  const willLeave = () => ({ y: spring(-100, presets.gentle), opacity: spring(0, presets.gentle) })

  const willEnter = () => ({ y: -100, opacity: 1})

  const defaultStyles = () => {
    return toastList.map(toast => ({...toast, style: {y: -100, opacity: 1}}))
  }

  const getStyles = () => {
    return toastList.map(toast => ({...toast, style: {y: spring(0, presets.gentle), opacity: spring(1, presets.gentle)}}))
  }

  const toastMarkup = () => {
    return (
      <TransitionMotion
        defaultStyles={defaultStyles()}
        styles={getStyles()}
        willLeave={willLeave}
        willEnter={willEnter}
      >
        {styles => 
          <Fragment>
            {
              styles.map(({ style, key, data: { content, variant, position }}) => {
                return (
                  <ToastContainer key={key} variant={variant} position={position} style={{
                    ...style,
                    transform: `translateY(${style.y}px)`,
                    WebkitTransform: `translateY(${style.y}px)`}}>
                    {content}
                  </ToastContainer>)
              })
            }
          </Fragment>
        }
      </TransitionMotion>
    )
  }

  return (
    <Portals>
      {toastMarkup()}
    </Portals>
  )
}

export default memo(Toast);