import styled from 'styled-components';
import { Flex } from 'rebass';
import { media, excludeMedia } from '../../utils/mediaBreakpoints';

// This switch the container/wrapper type based on screens;
// when inverted is true on mobile screen flex-direction is row.
// when inverted is false on mobile screen flex-direction is column;
const FlexContainerSwitch = styled(Flex).attrs(({ isInverted }) => ({ isInverted }))`
  ${media.mobile`
    flex-direction: ${({ isInverted }) => isInverted ? 'row' : 'column'};
  `}
  ${excludeMedia.mobile`
    flex-direction: ${({ isInverted }) => isInverted ? 'column' : 'row'};
  `}
`

export default FlexContainerSwitch;