//If in future you have to add new type of app add here
export const APP_TYPES = {
  PWA: 'PWA',
  NORMAL: 'NORMAL',
}

export const FEATURE_TAGS = {
  //add all feature tags here like
  WEARABLES: 'WEARABLES',
  IMPROVETAB: 'IMPROVETAB',
  REWARDS: 'REWARDS',
  CHALLENGE_OF_THE_WEEK: 'CHALLENGE_OF_THE_WEEK'
}

// if in future you added new app type in APP_TYPES
// add that app_type here is featured as well and set values as an array which will contain featured tags of that particular app_type
export const FEATURED = {
  PWA: [], // add pwa featured tags here
  NORMAL: [FEATURE_TAGS.WEARABLES, FEATURE_TAGS.IMPROVETAB, FEATURE_TAGS.REWARDS, FEATURE_TAGS.CHALLENGE_OF_THE_WEEK],
  //NEW_APP_TYPE: ['FEATURED_TAG1', 'FEATURED_TAG2', 'FEATURED_TAG3']  for future use
}