import styled, { css } from 'styled-components';
import { Box } from 'rebass';

/**
* Styled hyperlink texts which accepts variant, display, size as argument.
* display is a string which accepts css 'display' property values.
* size adds in the fontSize to the hyper link.
*/
const Link = styled(Box).attrs(({ variant, display, size }) => ({ as: 'a', variant, display, size}))`
cursor: pointer;
text-decoration-line: none!important;
&:focus, &:hover, &:visited, &:link, &:active {
  text-decoration: none;
}
letter-spacing: 0;
font-family: ${props => props.bold ? 'ProximaNova-Semibold' : 'ProximaNova'};
font-size: ${({ theme: { fontSizes }, size}) => fontSizes[size]};
margin-left: ${props => props.ml || props.mx || '0px'};
margin-right: ${props => props.mr || props.mx || '0px'};
margin-bottom: ${props => props.mb || props.my || '0px'};
letter-spacing: ${({letterSpacing}) => letterSpacing|| '0px' };
${({ theme: { links }, variant, disabled, display}) => css`
  color: ${links[variant].color};
  &:hover {
      ${!disabled && css`
          color: ${links[variant].hoverColor};
      `}
  }
  ${disabled && css`
      cursor: unset;
  `}
  ${display && css`
      display: ${display};
  `}
`}
`

Link.defaultProps = {
  variant: 'primary',
  size: 'sm'
}

export default Link;