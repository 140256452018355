import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const CardSubTitle = styled(Box).attrs(({ block }) => ({as: 'h6', block}))`
  color: ${({ theme: { colors }, color, muted }) => muted ? colors.lightGrey : (colors[color] || colors.subtitle)};
  font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize] ?? '0.875rem'};
  font-family: ${({ bold, bolder, theme: { fonts } }) => {
  if(bold) {
    return fonts.semiBold
  } else if (bolder) {
    return fonts.extraBold
  } else {
    return fonts.normal
  }
}};
  line-height: ${({ lineHeight }) => lineHeight ?? 1.57};
  letter-spacing: ${props => props.letterSpacing ?? '0.00714em'};
  margin: 0px;
  padding: 0px;
  display: ${props => props.display || 'inline'};
  text-align: ${props => props.textAlign || props.alignSelf || ''};
  margin-left: ${props => props.ml || props.mx || 0};
  margin-right: ${props => props.mr || props.mx || 0};
  margin-top: ${props => props.mt || props.my || 0};
  margin-bottom: ${props => props.mb || props.my || 0};
  padding-bottom: ${props => props.pb || props.py || '0.35em'};
  padding-left: ${props => props.pl || props.px || 0};
  padding-right: ${props => props.pr || props.px || 0};
  padding-top: ${props => props.pt || props.py || 0};
  ${({WebkitBoxOrient}) => WebkitBoxOrient && css` -webkit-box-orient: ${WebkitBoxOrient}`};
  ${({WebkitLineClamp}) => WebkitLineClamp && css` -webkit-line-clamp: ${WebkitLineClamp}`};
  ${({maxWidth}) => maxWidth && css` max-width: ${maxWidth}`};
  ${({overflow}) => overflow && css` overflow: ${overflow}`};
  ${({textOverflow}) => textOverflow && css` text-overflow: ${textOverflow}`};
  ${({whiteSpace}) => whiteSpace && css` white-space: ${whiteSpace}`};
  &:first-letter{
    text-transform: capitalize;
  }
`

CardSubTitle.defaultProps = {
  block: true
}

export default CardSubTitle