import React from 'react'
import { Button, Card } from '../atoms';
import { Flex } from 'rebass'
import { Text } from '../atoms/typography';
import styled, { css } from 'styled-components';
import { isMobile } from '../../utils/common';
import { media } from '../../utils/mediaBreakpoints';

const ExtendedCard = styled(Card)`
  width: 100%;
  background-color: #fafbfd;
  ${({cardRequired}) => cardRequired && css `
    position: fixed;
    bottom: ${props => props.bottom};
    z-index: 1000;
    box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.15);
  `}
  ${media.desktop`
    margin-left: -12px;
  `}
  ${media.tablet`
    width: 80%;
  `}

`
const ExtendedButton= styled(Button)`
  ${media.desktop`
    margin-right: -20px;
  `}
`

const ConsultationButton = ({text, subText, buttonText, onClick, cardRequired, bottom='66px', btnSize='md', borderRadius, color='lightBlue', ...props}) => {
  return(
    <ExtendedCard width={props.width} cardRequired={cardRequired} borderRequired={cardRequired} backgroundColor={'#F5F7F9'} p={4} bottom={bottom}>
      <Flex pt={'10px'} pl={'4px'} flexDirection='column'>
        <Text color={color} fontSize={props.fontSize} lineHeight='14px' bold>{text}</Text>
        <Text color='lightGrey' fontSize={props.fontSize} lineHeight='14px' pt={isMobile() ? 3 : 4} pb={'12px'}>{subText}</Text>
        <ExtendedButton mt={'16px'} size={btnSize} borderRadius={borderRadius} mr={4} mb={4} fontSize='md' bold variant='primary' onClick={onClick}>
          {buttonText}
        </ExtendedButton>
      </Flex>
    </ExtendedCard>
  )
}

ConsultationButton.defaultProps = {
  width: '100%',
  fontSize: 'sm'
}

export default ConsultationButton