import styled from 'styled-components'
const SectionSubHeading = styled.h5`
  margin-bottom: ${props => props.mb || props.my || '0.5em'};
	color: ${props => props.muted ? props.theme.colors.grey : props.theme.colors.title};
  font-size: ${props => props.theme.fontSizes[props.fontSize] || '1rem'};
  line-height: 1.2;
  letter-spacing: ${props => props.letterSpacing || '0.00938em;'}; 
  margin-left: ${props => props.ml || props.mx || 0};
  margin-right: ${props => props.mr || props.mx || 0};
  margin-top: ${props => props.mt || props.my || 0};
  padding-bottom: ${props => props.pb || props.py || 0};
  padding-left: ${props => props.pl || props.px || 0};
  padding-right: ${props => props.pr || props.px || 0};
  padding-top: ${props => props.pt || props.py || 0};
  display: block;
  text-align: ${props => props.alignSelf || ''};
`

export default SectionSubHeading;