import styled, { css } from 'styled-components';
import { Flex } from 'rebass';

const Text = styled(Flex)`
	color: ${({ theme: { colors }, color }) => colors[color] || color};
  font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize]};
  font-family: ${({ bolder, bold, theme: { fonts }}) => {
  if(bolder) {
    return fonts.extraBold
  } else if(bold) {
    return fonts.semiBold
  }
  else {
    return fonts.normal
  }
}};
  line-height: ${({ theme: { lineHeights }, lineHeight }) => lineHeights[lineHeight]};
  letter-spacing: ${({ theme: { letterSpaces }, letterSpace}) => letterSpaces[letterSpace]};
  ${({ cursor }) => cursor && css`
    cursor: ${cursor};
  `};
  ${({m}) => m && css` margin: ${m}`};
  ${({mb}) => mb && css` margin-bottom: ${mb}`};
  ${({mt}) => mt && css` margin-top: ${mt}`};
  ${({mr}) => mr && css` margin-right: ${mr}`};
  ${({ml}) => ml && css` margin-left: ${ml}`};
  ${p => p.bgColor && css `background-color: ${p.theme.colors[p.bgColor]}`};
  ${({borderRadius}) => borderRadius && css `border-radius: ${borderRadius}`};
  ${({opacity}) => opacity && css `opacity: ${opacity}`};
  ${({textAlign}) => textAlign && css `text-align: ${textAlign}`};
  ${({whiteSpace}) => whiteSpace && css `white-space: ${whiteSpace}`};
  ${({fontStyle}) => fontStyle && css `font-style: ${fontStyle}`};
`

Text.defaultProps = {
  lineHeight: 'md',
  fontSize: 'md',
  lineSpace: 'md',
  color: 'body',
  as: 'p',
}

export default Text
