import React from 'react';
import styled from 'styled-components';
import { Icon } from '../atoms';
import NavbarItemTitle from '../atoms/typography/NavbarItemTitle'
import { Link } from 'react-router-dom';

const DropdownSlot = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  perspective: 1000px;
  z-index: 1000;
`;
const NavbarItemEl = styled.li.attrs(() => ({ as: Link }))`
  position: relative;
  list-style: none;
  text-decoration: none;
  &::first-of-type {
    margin-left: 0;
  }
`;

const NavbarItem = props => {
  const {
    title,
    icon,
    children,
    link: { to },
    onMouseEnter,
    ...restProps
  } = props;
  return (
    <NavbarItemEl
      to={to}
      onMouseEnter={() => onMouseEnter(props.index)}
      onFocus={onMouseEnter}
      {...restProps}
    >
      <NavbarItemTitle>
        {' '}
        {React.isValidElement(icon) ? icon : (icon.src ? <Icon mx={3} src={icon.src} size='md'/> : null)}
        {title}
      </NavbarItemTitle>
      <DropdownSlot>{children}</DropdownSlot>
    </NavbarItemEl>
  );
};

NavbarItem.defaultProps = {
  link: {},
  icon: {}
};

export default NavbarItem;
