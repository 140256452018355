import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const Label = styled(Box).attrs(({ theme: { space, letterSpaces, fontSizes }, size = 'lg' }) => {
  let configure = {}
  switch (size) {
  case 'xl': {
    configure = {
      margin: `${space[6]/16}em`, // 2em margin
      padding: `${space[6]/16}em`,
      letterSpacing: `${letterSpaces[size]}`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  } case 'lg': {
    configure = {
      margin: `${space[5]/16}em`, // 1em margin
      padding: `${space[5]/16}em`,
      letterSpacing: `${letterSpaces[size]}`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  } case 'md': {
    configure = {
      margin: `${space[4]/16}em`, // 0.5em margin
      padding: `${space[4]/16}em`,
      letterSpacing: `${letterSpaces[size]}`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  } case 'sm': {
    configure = {
      margin: `${space[3]/16}em`, // 0.25em margin
      padding: `${space[3]/16}em`,
      letterSpacing: `${letterSpaces[size]}`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  } case 'xs': {
    configure = {
      margin: `${space[2]/16}em`, // 0.125em margin
      padding: `${space[2]/16}em`,
      letterSpacing: `${letterSpaces[size]}`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  }
  }
  return {
    ...configure,
    as: 'label'
  }
})`
  letter-spacing: ${({ lsp, letterSpacing }) => lsp || letterSpacing};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  ${({ textTransform }) => textTransform && css`
    text-transform: ${textTransform};
  `}
  font-family: 'ProximaNova-Semibold';
  font-size: ${({ fontSize }) => fontSize};
  margin-top: ${({ mt, my, m }) => mt || my || m || '1em'};
  margin-bottom: ${({ mb, my, m }) => mb || my || m || '0.2em'};
  margin-left: ${({ ml, margin }) => ml || margin};
  padding-left: ${({ pl, padding }) => pl || padding};
  color: ${({ variant, theme: { colors } }) => colors[`${variant}`] || colors.subtitle};
`

Label.defaultProps = {
  textTransform: 'uppercase'
}

export default Label;