import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const Wrapper = styled(Box)`
	text-align: ${props => props.alignSelf};
	${({ display }) => display && css`
		display: ${display};
	`}
`

export default Wrapper