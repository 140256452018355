import React, { useEffect, useState, lazy } from 'react';
import Input from './Input'; 
import styled, { css } from 'styled-components';
import Icon from './Icon';
import { Flex } from 'rebass';
const LazyDatePicker = lazy(() => import('./DatePickerLazy'));

const DivWthBorder = styled(Flex)`
  text-align: ${props => props.textAlign };
  border-radius: ${props => props.borderRadius};
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  width: ${props => props.width};
  border: 1px solid ${({ theme: { borders }}) => borders.input};
  max-width:${props => props.width};
  justify-content: space-between;
`

const ExtendedInput = styled(Input)`
  text-transform: uppercase;
  color: #4d6189;
  word-spacing: 20px;
  height: ${props => props.height};
  border: none;
  ${({disabled}) => disabled && css`
    pointer-events: none;
  `}
  margin: 0 auto;
  margin-left: 0px;
`

const CustomDatePickerWithAction = ({onChange, dob, showYearDropdown, showMonthDropdown, selectedDob, disabled, textAlign, width, color, onChangeKey, height, borderRadius, url, backgroundColor}) => {
  const [startDate, setStartDate] = useState(null);


  useEffect(() => {
    if(startDate != null){
      if(onChangeKey) {
        onChange(startDate)
      }
      else {
        onChange(document.getElementById('date-picker').value)
      }
    }
  }, [startDate])

  const ExampleCustomInput = ({ value, onClick }) => (
    <DivWthBorder borderRadius={borderRadius} backgroundColor={backgroundColor} width={width}>
      <ExtendedInput bold disabled={disabled} textAlign={textAlign} height={height} color={color} fontSize='md' placeholder="DD/MM/YYYY" id='date-picker' value={value} onClick={onClick} borderRadius={borderRadius} />
      {url && <Icon mt={'12px'} src={url} size='md' mr={'12px'} />}
    </DivWthBorder>
  );

  return (
    <>
      <LazyDatePicker
        selected={startDate}
        onChange={date =>setStartDate(date)}
        customInput={<ExampleCustomInput />}
        dateFormat="dd-MM-yyyy"
        maxDate={dob ? (new Date()) : false}
        value={selectedDob ? selectedDob : startDate}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
      />
    </>
  )
} 

CustomDatePickerWithAction.defaultProps = {
  showMonthDropdown: true,
  showYearDropdown: true,
  textAlign: 'center',
  width: '100%',
  color: '#a4bocc'
}

export default CustomDatePickerWithAction;