import styled from 'styled-components';
import { media, excludeMedia } from '../../utils/mediaBreakpoints';

const BottomBar = styled.div`
  ${excludeMedia.mobile`display: none;`}
  ${media.mobile`
    position: fixed;
    display: flex;
    padding: ${props => props.p || '10px 0px'};
    bottom: 0px;
    width: 100%;
    justify-content: space-around;
    background-color: ${({ theme: { colors }}) => colors.white};
    border-radius: ${props => props.radius || '5px'};
    border-top-width: 1px;
    border-top-color: #E5E8F0;
    overflow: hidden;
    z-index: 99;
    box-shadow: 0px -18px 18px 0px rgba(0, 0, 0, 0.05);
    -webkit-tap-highlight-color: transparent;
  `}
`

export default BottomBar;