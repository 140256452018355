import React from 'react'

const ProgressBar = ({height='10', color='#2168f3', width, containerColor, rx='5'}) => {
  return(
    <svg data-testid="progressbar" xmlns="http://www.w3.org/2000/svg" height={height} width='100%'>
      <rect width="100%" height={height} x="0" y="0" rx={rx} fill={containerColor}/>
      <rect width={width} className='animated-bar' height={height} x="0" y="0" rx={rx} fill={color} />
    </svg>
  )
}

ProgressBar.defaultProps = {
  containerColor: '#f4f4f4'
}

export default ProgressBar;