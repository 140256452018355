import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'rebass'; 
import { Button } from '../atoms';

const SelectOption = styled(Button).attrs(({ active, disabled }) => ({
  active, disabled
}))`
  border-radius: 18px;
  font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize]};
  font-family: ${({ theme: { fonts }}) => fonts.semiBold};
  color: ${({ theme: { colors }, color}) => colors[color]};
  ${({ borderRequired}) => borderRequired && css`border: 1px solid ${props => props.theme.colors.border};`}
  background-color: ${({ theme: { colors }, backgroundColor}) => colors[backgroundColor]};
  &:hover {
    color: ${({ theme: { colors }}) => colors.white};
    background-color: ${({ theme: { colors }}) => colors.primary};
  }
  ${({ active, disabled }) => {
  if(disabled) {
    return disabled && css`
        color: ${({ theme: { colors }}) => colors.lightGrey};
        background-color: ${({ theme: { colors }}) => colors.bodyHoverColor};
      `
  }
  return active && css`
      color: ${({ theme: { colors }}) => colors.white};
      background-color: ${({ theme: { colors }}) => colors.primary};
    `
}}
`
const ExtendedFlex = styled(Flex)`gap: 10px;`

const MultiSelectOptions = ({options, onClick, flexWrap, justifyContent, width, backgroundColor='lightestGrey', color='body', border='lighterGrey', fontSize, size, borderRequired}) => {
  return (
    <ExtendedFlex flexWrap={flexWrap} justifyContent={justifyContent} width={width}>
      {options.map((option, index) => {
        if(React.isValidElement(option.text)) {
          return option.text;
        }
        return <SelectOption size={size} fontSize={fontSize} borderRequired={borderRequired} key={index} disabled={option.disabled} onClick={onClick} data-position={index} active={option.selected} backgroundColor={backgroundColor} color={color} border={border} letterSpacing={'0'}>{option.text}</SelectOption>})}
    </ExtendedFlex>)
}

MultiSelectOptions.defaultProps = {
  flexWrap: 'wrap',
  width: '100%',
  borderRequired: true,
  fontSize: '16px'
}

export default MultiSelectOptions;