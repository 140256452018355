import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { media } from '../../utils/mediaBreakpoints';
const Input = styled(Box).attrs(() => ({as: 'input'}))`
	width: ${({width})=>(width ? width: '100%')};
	background-image: url(https://res.cloudinary.com/ekincare/image/upload/v1548406895/Normal%20HealthChecks%20Web/search_icon.svg); 
	background-size: 20px 20px;
	background-position: 13px 11px; 
	padding-left: 45px; 
	background-repeat: no-repeat;
	min-height: 43px;
	border: 1px solid #ced4da;
	border-radius: ${props => props.bgRadius || '.2em'};
	font-size: 16px;
	color: ${({theme, color}) => theme.colors[color ? color : 'disabledText']};
	&:focus {
		outline: none;
	};
	${media.mobile`
		margin-top: ${({ mt }) => mt ? mt : '10px'};
		margin-left: 5px;
		margin-right: 5px;
	`}
	background-color: ${props => props.theme.colors.white};
	&:placeholder {
		margin: 10px;
	};
`
const SearchBox = ({onChange, value='', ...props}) => {
  return(
    <Input placeholder={props.placeholder} onChange={(e)=>onChange(e)} value={value} {...props}/>
  )
}

export default SearchBox;