import styled from 'styled-components';
import { Box } from 'rebass';

const Alignment = styled(Box)`
	text-align: ${props => props.align};
` 

Alignment.defaultProps = {
  align: 'center'
}

export default Alignment;