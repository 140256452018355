import { useState, useEffect } from "react";

const useIsMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth);
  function handleResize() {
    setIsMobileView(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () =>{
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return isMobileView < 600;
}
export default useIsMobileView;
