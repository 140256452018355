export const healthChecksInitialState = {
  recent_package_searches: ["Full Body Check Up", "HBA1C", "Thyriod"],
  searchTerm: "",
  packages: [],
  selected_packages: [],
  categories: [],
  self: true, 
  modal: {showFamilyMemberModal: false, visibile: false, showAddFamilyMemberModal: false},
  showFaqModal: false,
  dependent: false,
  ewap: {},
  from: '',
  //add the different modules here
}

export const selectedLabtestInitialState ={
  cart:[],
  seletedMembers:[],
  previouslyBookedPackages:null,
  popularFunctionTests:null,
  popularPackages:null,
  popularLabTests:null
}

export const addNewFamilyMemberInitialState ={
  benefitConfigDetails: {},
  selectedPackage: {},
  seletedMembers:[],
  isFromBenefitDrawer: false,
  doctorConsultationData: {},
  labTestDataFromAddFamilyMember: {}
}

export const pharmacyInitialState = {
  customerId: null,
  cart: [],
  addresses: [],
  currentAddress: {},
  emptyCartPage: false,
  cartSummary: {},
  currentCategoryData: undefined,
  serviceable: null,
  showAddressModal: false,
  mobileNumber: '',
  prescriptionsReviewData: {},
  loading: false,
  reminder: false,
  openAlertModal:false,
  selectedPrescriptions: [],
  pastPrescriptions: [],
  globalCartUpdate: false,
  isPrescriptionRemove: false,
  isCartLoading: true,
  subscriptionId: null,
  showValuePlusCartModal: false,
  isValuePlusPlanChanged: false,
  selectedValuePlusSubscription: null,
  cityName: 'Bangalore',
  zipCode: '560001',
  suggestedCoupon: {},
  //add the different modules here
}

