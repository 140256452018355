
const serviceWorkerConfig = {
  onUpdate: function(registration) {
    if(registration && registration.waiting) {
      registration.waiting.postMessage({type: 'SKIP_WAITING'})
      registration.waiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          console.log('updating...')
          window.location.reload();
        }
      });
    }
  }
}

export default serviceWorkerConfig;