import * as Actions from './Actions'
import { healthChecksInitialState } from '../../store'

const ekincareReducer = (state, action) => {
  switch (action.type) {
  case Actions.OPEN_SEARCH_PACKAGES_MODAL:
    return {
      ...state,
      modal: {...state.modal, visibile: true, defaultSearch: action.payload.defaultSearch},
      from: action.payload.from
    }
  case Actions.CLOSE_SEARCH_PACKAGES_MODAL:
    return {
      ...state,
      modal: {...state.modal, visibile: false},
      searchTerm: ''
    }
  case Actions.CLEAR_RECENT_PACKAGE_SEARCH:
    return {
      ...state,
      recent_package_searches: [],
    }
  case Actions.SEARCH_PACKAGES:
    return {
      ...state,
      searchTerm: action.payload,
    }
  case Actions.SET_PACKAGES:
    return {
      ...state,
      packages: action.payload.packages,
      categories: action.payload.categories
    }
  case Actions.REMOVE_PACKAGE:
    return {
      ...state,
     
      selected_packages: state.selected_packages.filter(
        (item) => item.id != action.payload.id,
      ),
      categories: action.payload.categories,
    }
  case Actions.OPEN_FAMILY_MEMBER_MODAL:
    return {
      ...state,
      modal: {...state.modal, showFamilyMemberModal: true},
    }
  case Actions.CLOSE_FAMILY_MEMBER_MODAL:
    return {
      ...state,
      modal: {...state.modal, showFamilyMemberModal: false},
    }
  case Actions.SELECT_FAMILY_MEMBER:
    //check the payload here later
    return {
      ...state,
      modal: {...state.modal, showFamilyMemberModal: false},
      customer: action.payload,
      dependent: true,
      self: false,
      
    }
  case Actions.SET_CUSTOMER:
    return {
      ...state,

      customer: action.payload,
      main_customer: action.payload,
      dependent: true,
      self: false,
    }
  case Actions.SET_MAIN_CUSTOMER:
    return {
      ...state,
      customer: action.payload,
      self: true,
      dependent: false,
    }
  case Actions.ADD_PACKAGES_AND_CLOSE_MODAL:
    return {
      ...state,
      payment_details_data: action.payload,
      selected_packages: [action.selectedPackage],
      searchTerm: '',
      modal: {showFamilyMemberModal: false, visibile: false},
    }
  case Actions.ADD_PACKAGE:
    return {
      ...state,

      selected_packages: [
        ...state.selected_packages,
        action.payload,
      ],
    }
  case Actions.SHOW_ADD_FAMILY_MEMBER_MODAL:
    return {
      ...state,
      modal: {
        ...state.modal,
        showAddFamilyMemberModal: true,
        showFamilyMemberModal: false,
        visibile: true,
      },
    }
  case Actions.CLOSE_ADD_FAMILY_MEMBER_MODAL:
    return {
      ...state,
      modal: {
        ...state.modal,
        showAddFamilyMemberModal: false,
        showFamilyMemberModal: false,
        visibile: false,
      },
    }
  case Actions.RELOAD_FAMILY_MEMBERS:
    return {
      ...state,
      reload_family_members: true,
    }
  case Actions.RESET_MODALS:
    return {
      ...state,
      modal: {
        ...state,
        showFamilyMemberModal: false,
        showAddFamilyMemberModal: false,
        visible: false,
      },
    }
  case Actions.OPEN_FAQ_MODAL:
    return {
      ...state,
      showFaqModal: true,
    }
  case Actions.CLOSE_FAQ_MODAL:
    return {
      ...state,
      showFaqModal: false,
    }
  default:
    return healthChecksInitialState
  }
}

export default ekincareReducer
