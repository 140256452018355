export const SET_BOOKING_HISTORY_DATA = "SET_BOOKING_HISTORY_DATA"
export const SET_CANCEL_APPOINTMENT_DATA = "SET_CANCEL_APPOINTMENT_DATA"
export const CLOSE_CANCEL_APPOINTMENT_MODAL = "CLOSE_CANCEL_APPOINTMENT_MODAL"
export const OPEN_CANCEL_APPOINTMENT_MODAL = "OPEN_CANCEL_APPOINTMENT_MODAL"
export const OPEN_BOOKING_HISTORY_MODAL = "OPEN_BOOKING_HISTORY_MODAL"
export const CLOSE_BOOKING_HISTORY_MODAL = "CLOSE_BOOKING_HISTORY_MODAL"
export const OPEN_RESCHEDULE_APPOINTMENT_MODAL = "OPEN_RESCHEDULE_APPOINTMENT_MODAL"
export const OPEN_RESCHEDULE_APPOINTMENT_FROM_CANCEL_MODAL = "OPEN_RESCHEDULE_APPOINTMENT_FROM_CANCEL_MODAL"
export const CLOSE_RESCHEDULE_APPOINTMENT_FROM_CANCEL_MODAL = "CLOSE_RESCHEDULE_APPOINTMENT_FROM_CANCEL_MODAL"
export const CLOSE_RESCHEDULE_APPOINTMENT_MODAL = "CLOSE_RESCHEDULE_APPOINTMENT_MODAL"
export const SET_FEEDBACK_RATING = "SET_FEEDBACK_RATING"
export const LOADING = "LOADING"
export const TAB_CHANGE = "TAB_CHANGE"