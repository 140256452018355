import styled from 'styled-components';
import { media } from '../../../utils/mediaBreakpoints';

const NavbarItemTitle = styled.div`
  background: transparent;
  border: 0;
  font-family: 'ProximaNova-Semibold';
  font-size: 16px;
  padding: 1.5rem 1.5rem 1.2rem 0.5rem;
  color: ${props => props.theme.colors.extraInformation};
  transition: opacity 250ms;
  display: flex;
  ${media.tabletS`
    flex-direction: column;
    align-items: center;
  `}
  justify-content: center;
  cursor: pointer;
  /* position above the dropdown, otherwise the dropdown will cover up the bottom sliver of the buttons */
  position: relative;
  z-index: 2;
`;

export default NavbarItemTitle;