import styled, { css } from 'styled-components';
import { Text as Base } from 'rebass';

const Text = styled(Base)`
    display: inline;
    ${({ textTransform }) => textTransform && css`
        text-transform: ${textTransform};
    `}
    color: ${({color,theme:{colors}}) => colors[color] };
    font-size: ${props => props.theme.fontSizes[props.fontSize] || props.theme.fontSizes['md'] };
    ${({ml}) => ml && css` margin-left: ${ml}`};
    ${({mt}) => mt && css` margin-top: ${mt}`};
    ${({mb}) => mb && css` margin-bottom: ${mb}`};
    ${({textAlign}) => textAlign && css` text-align: ${textAlign}`};
    
`

export default Text;
