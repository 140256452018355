import styled, {keyframes } from 'styled-components';
import React from 'react';
import {Text} from 'rebass';
const dotKeyframes = keyframes`
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
    }
  40% {
    color: #2368f9;;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
    }
  60% {
    text-shadow:
      .25em 0 0 #2368f9,
      .5em 0 0 rgba(0,0,0,0);
    }
  80%, 100% {
    text-shadow:
      .25em 0 0 #2368f9,
      .5em 0 0 #2368f9;
  }  
`

const Dots = styled.p`
  text-align: center;
  font-size: 15px;
  padding-right: 25px;
  font: ${p => p.fontSize ? `100 ${p.fontSize}/150% Impact` : `100 3em/150% Impact`};
  margin-bottom: 0px;
  :after {
    content: ' .';
    animation: ${dotKeyframes} 1s steps(5, end) infinite;
  }
`


const ThreeDotLoader = ({ loadingText, size, textSize="13px" }) => {
  return (
    <>
      <Dots fontSize={size}></Dots>
      {loadingText ? <Text fontSize={textSize} textAlign="center" color='dimgray'>{loadingText}</Text> : null}
    </>
  )
}

ThreeDotLoader.defaultProps = {
  loadingText: 'Finding the best doctor for you'
}


export default ThreeDotLoader