import { useState, useEffect } from "react";
import { APP_TYPES } from "../featureTagConstants";

function useAppType() {
  const [appType, setAppType] = useState(APP_TYPES.NORMAL);

  useEffect(() => {
    let type = APP_TYPES.NORMAL;

    if(process.env.REACT_APP_TYPE) {
      if(process.env.REACT_APP_TYPE === 'pwa') type = APP_TYPES.PWA;
    } else{
      const host = window.location.hostname;
      if (/^pwa/.test(host)) type = APP_TYPES.PWA;
    }
    
    setAppType(type);
  }, []);

  return appType;
}

export default useAppType;
