import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';
import { Link } from 'react-router-dom';

const ButtonBox = styled(Box).attrs(({ as }) => ({as: as || 'button'}))`
-webkit-tap-highlight-color: transparent;
width: ${props => props.block ? '100%' : ''};
${({ minWidth }) => minWidth && css`min-width: ${minWidth};`}
${({ minHeight }) => minHeight && css`min-height: ${minHeight};`}
cursor: ${props => (props.disabled || props.loading) ? 'not-allowed' : 'pointer'};
opacity: ${props => props.disabled ? .5 : 1};
${({ whiteSpace }) => whiteSpace && css`white-space: ${whiteSpace};`}
padding: ${props => props.styles.padding};
color: ${props => ({theme: { buttons } }) => buttons[props.variant].color};
background-color: ${props => ({theme: { buttons } }) => (props.disabled) ? props.theme.colors.thickGrey : buttons[props.variant].backgroundColor};
position: ${props => props.styles.position};
border-radius: ${props => props.br || props.styles.borderRadius};
${({ theme: { buttons }, variant}) => {
  return buttons[variant].borderColor && css`
  ${({ borderRequired}) => borderRequired && css`border: 1px solid ${buttons[variant].borderColor};`}
`}}
font-family: ${({ bold, bolder, theme: { fonts } }) => {
    if(bold) {
      return fonts.semiBold
    } else if (bolder) {
      return fonts.extraBold
    } else {
      return fonts.semiBold
    }
  }};
${({pt}) => pt && css`padding-top:${pt};`};
border-color: ${props => ({theme: { buttons,colors },borderColor }) => borderColor ? colors[borderColor] : buttons[props.variant].borderColor};
font-size: ${props => props.theme.fontSizes[props.fontSize] || props.styles.fontSize};
line-height: ${props => props.styles.lineHeight};
letter-spacing: ${props => props.letterSpacing};
${({ as }) => {
    return as === Link && css`
    text-decoration: none;
    border-width: 1px;
  `
  }}
a {
  color: ${props => ({theme: { buttons } }) => buttons[props.variant].color};
}
border-width: ${({borderWidth}) => borderWidth || ''};
border-style: ${({ borderStyle }) => borderStyle || 'solid'};
margin-left: ${props => props.ml || 0};
margin-right: ${props => props.mr || 0};
margin-top: ${props => props.mt || 0};
margin-bottom: ${props => props.mb || 0};
${({disableHover}) => !disableHover && css`
  &:hover {
    border-color: ${props => ({theme: { buttons, colors },borderColor }) => borderColor ? colors[borderColor] : buttons[props.variant].hoverBrColor};
    background-color: ${props => ({theme: { buttons } }) => (props.disabled || props.loading) ? buttons[props.variant].thickGrey : buttons[props.variant].hoverBgColor};
    color: ${props => ({theme: { buttons } }) => (props.disabled || props.loading) ? buttons[props.variant].color : buttons[props.variant].hoverColor};
    ${({ theme: { buttons }, variant}) => {
    return buttons[variant].hoverBrStyle && css`
        border-style: ${buttons[variant].hoverBrStyle};
      `}}
    a {
      color: ${({theme: { colors } }) => colors.white};
    }
 }
`};

&:focus {
  outline: 0;
}
`
const Icon = styled.i`
right: 8px;
top: -1px;
vertical-align: middle;
position: relative;
content: url(${props => props.url});
`
const Button = ({block=false, type, onClick, className, size, variant='default', whiteSpace, borderRequired,disableHover=false, ...props}) => {

  const ButtonCss = () => {
    let padding = props.padding || '6px 12px'
    let fontSize = '14px'
    let lineHeight = props.lineHeight || 1.428
    let borderColor = props.borderColor
    if (size === 'xs'){
      padding = '5px 10px'
      fontSize = '11px'
      lineHeight = 1.5
    }
    else if (size === 'sm'){
      padding = '5px 10px'
      fontSize = '12px'
      lineHeight = 1.5
    }
    else if (size === 'smL') {
      padding = '7px 11px'
      fontSize = '14px'
      lineHeight = '18px'
    } 
    else if (size === 'lg') {
      padding = '10px 16px'
      fontSize = '18px'
      lineHeight = 1.333
    } else if (size === 'md') {
      padding = '6px 12px'
      fontSize = '14px'
      lineHeight = 1.428
    } else if (size === 'mdL') {
      padding = '12px 24px';
      fontSize='16px'
    }
    else if (size === 'xl') {
      padding = '16px 26px';
    }
    else if (size === 'mxL') {
      padding = '13px 24px';
    }
    else if (size === 'mL') {
      padding = '18px 32px'
      fontSize = 'md'
      lineHeight='16px'
    }
    let result = { 'padding': padding, 'position': 'relative', 'fontSize': fontSize, 'borderRadius': props.borderRadius, 'borderColor': borderColor, 'lineHeight': lineHeight}
    return result
  }

  return(
    <ButtonBox borderRequired={borderRequired} whiteSpace={whiteSpace} styles={ButtonCss()} onClick={onClick} block={block} variant={type || variant} className={className} disableHover={disableHover} {...props}>
      {(props.loading || props.icon) ? <Icon url={props.url} /> : '' }
      {props.children}
    </ButtonBox>
  )
}
Button.defaultProps = {
  borderRequired: true,
}

export default Button;