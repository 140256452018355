const getOptions = (customerKey, multipartFormData = false) => {
  let options = { headers: {} };
  if (customerKey) {
    options["headers"]["X-FAMILY-MEMBER-KEY"] = customerKey;
  }
  if (multipartFormData) {
    options["headers"]["Content-Type"] = "multipart/form-data";
  }
  return options;
};

export default getOptions;
