import { Flex } from "rebass";
import { NavLink } from 'react-router-dom'
import styled, { css, keyframes } from "styled-components";
import { media } from "./mediaBreakpoints";
import { Text } from "../components/atoms/typography";

export const RouterNavLink = styled(Flex).attrs(() => ({ as: NavLink }))`
text-decoration: none;
color: ${({ theme: { colors }}) => colors.extraInformation};
display: block;
font-size: 13px;
font-family: ${({ theme: { fonts }}) => fonts.normal};
width: 100%;
display: flex;
justify-content: center;
-webkit-justify-content: center;
align-items: center;
`
export const SignInFooter = styled(Flex)`
z-index: 1;
${media.mobile`
  width: 100%;
  position: fixed;
  background-color: ${({ theme: { colors }}) => colors.white};
  bottom: 0;
  right: ${p => p.right};
  margin-left: -18px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
`}
`

export const Tab = styled(Text)`
  width: ${p => p.width || '50%'};
  justify-content: center;
  color: ${p => p.isActive ? p.theme.colors['thickBlue'] : p.theme.colors['subtitle']};
  border-bottom: ${p => p.isActive && css`2px solid ${p.theme.colors['thickBlue']}`};
`


export const Vibration = styled.div`
  ${props => props.vibrate && css`
    -webkit-animation: ${vibrateDiv} .1s 2;
    -webkit-animation-direction: alternate;
    animation: ${vibrateDiv} .1s 2;
    animation-direction: alternate;
  `};
`
const vibrateDiv = keyframes`
  0%  {margin-left: 0px;}
  50% {margin-left: 10px;}
  100% {margin-left: 0px;}  
`