import styled, { css } from 'styled-components';
import { media, excludeMedia } from '../../utils/mediaBreakpoints';
import { Box } from 'rebass';

// NOTE:: bottom-right, bottom-left won't align at bottom. it should be done through react-motion.
const setPosition = (position) => {
  switch(position) {
  case 'top-right':
  case 'bottom-right': {
    return css`
        align-self: flex-end;
      `
  }
  case 'top-left':
  case 'bottom-left': {
    return css`
      align-self: flex-start;
    `
  } case 'top-center': {
    return css`
        top: 60px;
        align-self: center;
      `
  }
  }
}

const Toast = styled(Box).attrs(({ variant }) => ({ variant, p: [4, 0] }))`
  position: relative;
  background-color: ${({ variant, theme: { toasts } }) => toasts[variant].backgroundColor};
  color: ${({ variant, theme: { toasts }}) => toasts[variant].color};
  font-family: ProximaNova, san-serif;
  z-index: 10000;
  ${excludeMedia.mobile`
    min-width: 100px;
    min-height: 30px;
    max-width: 500px;
    margin: 5px;
    padding: 10px;
    opacticy: 1;
    border-radius: 3px;
    ${({ position }) => setPosition(position)}
    -webkit-box-shadow: 0 1px 10px 0 rgba(0,0,0,.1), 0 2px 15px 0 rgba(0,0,0,.05);
    -moz-box-shadow: 0 1px 10px 0 rgba(0,0,0,.1), 0 2px 15px 0 rgba(0,0,0,.05);
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.1), 0 2px 15px 0 rgba(0,0,0,.05);
  `}
  ${media.mobile`
    width: 100%;
    min-height: 1em;
  `}
`

Toast.defaultProps = {
  variant: 'success'
}

export default Toast;

