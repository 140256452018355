import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';
import { Text } from './typography';
import Icon from './Icon'
import Card from './Card';
import { dropDownGrayTriangle } from '../../assets/icons'

const Wrapper = styled(Box)`
position: absolute;
z-index: ${props => props.zIndex};
width: ${props => props.width};
max-height: 220px;
overflow-y: auto;
overflow-x: hidden;
${({ borderRequired}) => borderRequired && css`border: 1px solid ${props => props.optionsBorderColor};`}
margin-top: ${props => props.marginTopOptions || '4px'};
border-radius: ${props => props.optionsBorderRadius};
box-shadow: ${props => props.optionsBoxShadow};
`

const DropdownItem = styled.div`
padding: ${props => props.itemPadding || '8px'};
border: 1px solid transparent;
cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
user-select: none;
font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize]};
font-weight: 600;
background-color: ${props => props.selected ? `#2168f3` : `white` };
color: ${props => props.selected ? `white` : '#4D6189' };
&:hover {
	background-color: #F1F5FF;
	color: #4D6189;
}
`

const DropdownList = ({list, selectedItem={}, itemPadding, onChange}) => {
  if (list?.length > 0){
    let output = list.map((item, index) => {
      let selected = false
      let name = item
      if (typeof(item) === "object"){
        selected = selectedItem && selectedItem.id === item.id ? true : false
        name = item.name
      }
      else {
        selected = selectedItem && selectedItem === item ? true : false
      }
      return(<DropdownItem key={index} itemPadding={itemPadding} disabled={item.disabled} selected={selected} onClick={() => !item.disabled && onChange(item)}>{name}</DropdownItem>)
    })
    return output
  }
  else {
    return(<DropdownItem />)
  }
}

const Dropdown = ({title, list, selectedItem, toggleList, onChange, listOpen, borderRadius, padding, backgroundColor, borderColor, minHeight, mr, dropDownIcon=dropDownGrayTriangle, marginTopOptions, width='100%', optionsBorderRadius, borderRequired, optionsBorderColor, optionsBoxShadow, iconSize='sm', zIndex='1000', closeOnClickOverlay = false, itemPadding, disabledTextColor, ...props}) => { 
  
  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleList();
      }
    }
  
    if (listOpen && closeOnClickOverlay) {
      document.addEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [listOpen, toggleList, closeOnClickOverlay]);
  
  const onDropDownclick = () => {
    toggleList()
  }
  let color = ['DD', 'MM', 'YYYY'].includes(title) ? 'disabledText' : props.titleColor

  return(
    <div ref={dropdownRef} className={`block relative ${props.disabled ? 'pointer-events-none' : ''}`}>
      <Card minHeight={minHeight} justifyContent='space-between' backgroundColor={backgroundColor || 'backgroundColor'} borderColor={borderColor || 'borderGrey'} flexDirection='row' padding={padding || '12px'} alignItems='center' borderRadius={borderRadius || '6px'} pointer onClick={onDropDownclick}>
        <Text color={ props.disabled ? (disabledTextColor || 'disabledText') : color} fontSize={props.fontSize || 'md'} lineHeight={props.lineHeight || '16px'} bold>{title}</Text>
        <Icon mr={mr} size={iconSize} opacity={props.disabled ? '0.5' : '1'} src={dropDownIcon} rotate={listOpen ? '180' : '0'}/>
      </Card>
      {listOpen ? <Wrapper zIndex={zIndex} borderRequired={borderRequired} marginTopOptions={marginTopOptions} width={width} optionsBorderRadius={optionsBorderRadius} optionsBorderColor={optionsBorderColor} optionsBoxShadow={optionsBoxShadow}>
        <DropdownList itemPadding={itemPadding} fontSize={props.fontSize} list={list} selectedItem={selectedItem} onChange={onChange} />
      </Wrapper> : null}
    </div>
  )
}

Dropdown.defaultProps = {
  titleColor: 'darkGrey',
  optionsBorderColor: 'rgba(0, 0, 0, 0.1)',
  borderRequired: true,
  disabled: false
}

export default Dropdown;