import React from 'react';
import { Modal } from '../../molecules';
import { Text } from '../../atoms/typography';
import { Button, Card } from '../../atoms';
import { CALL_CONSULTATION_24BY7 } from '../../../routes/private';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const CardWrapper = styled(Card)`
    min-height: 200px;
    position: relative;
`
const FlexWrapper = styled(Flex).attrs(() => ({ p: 4, m:5 }))``

const ChatEnquiryNow = ({ onCloseModal, fdEnquiryNowData: { title, description } }) => { 
  const { push } = useHistory();
  const onClickScheduleCall = () => {
    window.setTimeout(onCloseModal)
    push(CALL_CONSULTATION_24BY7)
  }
  return (
    <Modal size='xxs' closeModal={onCloseModal}>
      <CardWrapper>
        <FlexWrapper flexDirection='column'>
          <Flex flexDirection='column' alignItems='flex-start' mb={5}>
            <Text fontSize='lg' color='extraInformation' bolder pb={3}>Enquiry</Text>
            <Text fontSize='md' color='subtitle' textAlign='start' py={3} bold>{title}</Text>
            <Text fontSize='md' color='subtitle' pb={3} mb={2}>{description}</Text>
          </Flex>
          <Flex justifyContent='space-between'>
            <Button
              p={0}
              variant='primary-outline'
              bold
              block
              minWidth='130px'
              mr={5}
              onClick={onCloseModal}
            >
              Yes, Answered</Button>
            <Button p={0} variant='primary' bold block minWidth='130px' onClick={onClickScheduleCall}>
              No, Schedule call
            </Button>
          </Flex>
        </FlexWrapper>
      </CardWrapper>
    </Modal>
  )
}

export default ChatEnquiryNow;