import React, {useState, useEffect, useContext} from 'react' 
import Modal from '../../molecules/Modal';
import FileUploader from '../../molecules/FileUploader';
import {Card, CardTitle, Icon, ToggleVisibility} from '../../atoms'
import {Flex, Box} from 'rebass'
import HomeAPI from '../../../services/home.api';
import styled from 'styled-components';
import {MEDICAL_REPORTS} from '../../../routes/private';
import ModalContext from '../../../utils/contexts/ModalContext'
import { close, backBlack } from '../../../assets/icons';
import toast from '../../../utils/toast';

const ModalHeader = styled(CardTitle)`
  border-bottom: 1px solid ${props => props.theme.colors.border};
  padding: 0 20px;
  margin: 0px -20px;
`

const UploadMedicalRecord = ({history, type='emr', showMemberSelection, showTypeSelection, customer}) => {
  const [familyMembers, updateFamilyMembers] = useState([])
  const [documentType, updateDocumentType] = useState(type)
  const [selectedCustomer, updateSelectedCustomer] = useState({})
  const showModal = useContext(ModalContext)

  const closeModal = () => {
    showModal({showModal: false})
  }

  const items = [
    {'title': 'Lab Report', 'type': 'emr'},
    {'title': 'Prescription', 'type': 'edp'}
  ]

  const heading = {
    "emr": "Upload documents",
    "edp": "Upload Prescriptions",
    "emi": "Upload Medical Invoices"
  }

  const onFetchSuccess = (response) => {
    updateFamilyMembers(response.data.members)
    let findIndex = response.data.members.findIndex((e) => e.relation === 'Self')
    updateSelectedCustomer(response.data.members[findIndex])
  }

  const onFetchError = () => {
    return 
  }

  const fetchFamilyMembers = () => {
    HomeAPI.fetchFamilyMembers({}, onFetchSuccess, onFetchError)
  }

  const onUploadSuccess = () => {
    closeModal()
    if (history.location.pathname === '/medical-reports') {
      window.location.reload()
    }
    else {
      history.push(MEDICAL_REPORTS)
    }
    toast.success('Successfully uploaded')
  }

  const setSelectedCustomer = (val) => {
    let findIndex = familyMembers.findIndex((e) => e.customer_token === val)
    updateSelectedCustomer(familyMembers[findIndex])
  }

  const onUploadFailure = () => {

  }

  useEffect(() => {
    fetchFamilyMembers()
  }, [])

  return (
    <Modal closeModal={closeModal}
      closeIcon={false}>

      <Card px="20px" py="10px" mx='auto' width="100%">
        <ModalHeader>
          <ToggleVisibility visibleOn={{desktop: false, tablet: false}}>
            <Flex mb='10px'><Icon src={backBlack} onClick={closeModal} size='lg' /></Flex>
          </ToggleVisibility>
          <Flex justifyContent='space-between'>
            <Box>
              <CardTitle>{heading[documentType]}</CardTitle>
            </Box>
            <ToggleVisibility visibleOn={{mobile: false}}>
              <Box> 
                <Icon src={close} size='lg' onClick={closeModal} />
              </Box>
            </ToggleVisibility>   
          </Flex>
        </ModalHeader>
        {showTypeSelection && 
          <Flex px="10px" py="10px">
            <label>What type of record are you uploading?</label>
          </Flex>
        }
        {showTypeSelection && <Flex px="10px">  
          <select value={documentType} onChange={(e) => {updateDocumentType(e.target.value)}}>
            {
              items.map((e, index) => {
                return (<option value={e.type} key={index}>{e.title}</option>)
              })
            } 
          </select>
        </Flex>}
        {showMemberSelection && <Flex px="10px" py="10px">
          <label>Who are you uploading this for?</label> 
        </Flex> }
        {showMemberSelection && <Flex px="10px" mb="20px">  
          <select value={selectedCustomer.customer_token} onChange={(e) => {setSelectedCustomer(e.target.value)}}>
            {
              familyMembers.map((e) => {
                return (<option value={e.customer_token} key={e.id}>{e.name}</option>)
              })
            } 
          </select> 
        </Flex>
        }
        <FileUploader customer={customer || selectedCustomer} type={documentType} onSuccess={onUploadSuccess} onFailure={onUploadFailure}/>
      </Card>
    </Modal>
  )
}

UploadMedicalRecord.defaultProps = {
  showMemberSelection: true,
  showTypeSelection: true
}

export default UploadMedicalRecord