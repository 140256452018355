import React from 'react';
import ContentLoader from "react-content-loader";

const ListItemLoader = () => (
  <ContentLoader 
    height={60}
    width={600}
    speed={2}
    primaryColor="#ebedf0"
    secondaryColor="#c1bebe"
  > 
    <rect x="20" y="20" rx="0" ry="0" width="32" height="35" />
    <rect x="85" y="20" rx="0" ry="0" width="250" height="9" />
    <rect x="85" y="40" rx="0" ry="0" width="180" height="9" />
  </ContentLoader>
)

export default ListItemLoader;
