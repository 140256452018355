import React from "react";
import styled from "styled-components";
import { Button, Card, Icon } from "../atoms";
import { locationPinWhiteColor } from "../../assets/icons"

const ExtendedCard = styled(Card)`
  width: ${({ props }) => props.width};
  position: ${({ props }) => props.position};
  bottom: ${({ props }) => props.bottom};
  z-index: 1000;
`;
const ExtendedIcon = styled(Icon)`
width: ${({width, theme: { iconSizes }}) => iconSizes[width]};
height: ${({height, theme: { iconSizes }}) => iconSizes[height]};
margin-right: #${({ml}) => ml}
`;
const ExtendedButton = styled(Button)`
  width: ${({width}) => width ? width : "100%"};
  align-self: ${({alignButton}) => alignButton ? alignButton : "center"};
`;
const FooterButton = ({
  borderRequired,
  text,
  updateStep,
  disabled,
  eventName,
  eventData,
  width,
  size,
  buttonWidth,
  alignButton,
  ...props
}) => {
  const { ml, pl, pr, mt, mb, pt, mr, borderRadius } = props;
  return (
    <ExtendedCard
      borderRequired={borderRequired}
      borderRadius={borderRadius}
      pl={pl}
      pr={pr}
      pt={pt}
      mb={mb}
      pb={4}
      mt={mt}
      ml={ml}
      mr={mr}
      width={width}
      props={props}
    >
      <ExtendedButton
        width={buttonWidth}
        alignButton={alignButton}
        size={size}
        disabled={disabled}
        data-event-name={eventName}
        data-event-data={JSON.stringify(eventData)}
        fontSize="md"
        bold
        variant={disabled ? "disabled" : "primary"}
        onClick={updateStep}
        url={props.url}
        icon={props.icon}
        borderRadius={props.borderRadius}
      >
        {text === "TRACK ORDER" && <ExtendedIcon src={locationPinWhiteColor} width="sm" height="md" mr={"8px"} />}
        {props.lowercase ? text : text.toUpperCase()}
      </ExtendedButton>
    </ExtendedCard>
  );
};
FooterButton.defaultProps = {
  ml: 0,
  bottom: "66px",
  position: "fixed",
  pr: 4,
  pl: 4,
  pt: "12px",
  width: "100%",
  borderRadius: "0.25em",
  size: "md",
  borderRequired: true,
  url: null,
  icon: null
};
export default FooterButton;
