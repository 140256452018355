import React from 'react';
import { close } from '../../assets/icons';
import GetApp from '../../assets/images/download-app.svg';
import { Card, Image, Icon, CardTitle, CardText } from '../atoms';
import AppDownloadButtonsGroup from './AppDownloadButtonsGroup';

const AppDownloadTile = ({header, closeModal, subTitle, className}) => {
  return(
    <Card width={['100%', "575px"]} height={['100%', '291px']} className={className}>
      {closeModal && <div className='flex flex-row-reverse'><Icon mr={5} mt={4} src={close} onClick={closeModal} pointer size='lg'/></div>}
      <div className='flex justify-between flex-col sm:flex-row items-center px-4 sm:px-0 bg-white bg-transparent'>
        <div className='sm:block hidden'>
          <Image src={GetApp} width="241px" />
        </div>
        <div className='flex flex-col px-5 mb-5 sm:mb-0'>
          <div className='flex flex-col text-center sm:text-left'> 
            {React.isValidElement(header) ? header :
              (header ? <CardTitle lineHeight={1.5} pb={0} mb={0} fontSize='lg' color="grey">{header}</CardTitle>: null)
            }
            {React.isValidElement(subTitle) ? subTitle :
              (subTitle ? <CardText pb={0} mb={0} color='grey'>{subTitle}</CardText> : null)
            }
          </div>
          &nbsp;
          <AppDownloadButtonsGroup/>
        </div>
      </div>
    </Card>
  )
}

AppDownloadTile.defaultProps = {
  subTitle: "App provides a better experience and more features!",
  header: "This feature is avaliable on mobile app only"
}

export default AppDownloadTile;