import styled, { css } from 'styled-components';
import { Box } from 'rebass';
import theme from '../../themes';

const Input = styled(Box).attrs(({as, p, mb , maxLength,lineHeight}) => ({as: as, p, mb, maxLength, lineHeight }))`
  ${({ backgroundColor }) => backgroundColor && css`background-color: ${props => props.backgroundColor};`}
  font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize]};
  font-family: ${({ bolder, bold, theme: { fonts }}) => {
  if(bolder) {
    return fonts.extraBold
  } else if(bold) {
    return fonts.semiBold
  } else {
    return fonts.normal
  }
}};
  line-height: ${props => props.lineHeight}
  maxlength: ${props => props.maxLength}
  ${({letterSpacing}) => letterSpacing && css`letter-spacing: ${letterSpacing}`};
  ${p => p.pt && css`padding-top:${p.pt}`};
  margin-top: ${({ mt }) => mt || '0px'};
  border: 1px solid ${({ theme: { borders }}) => borders.input};
  ${({borderRadius}) => borderRadius && css`border-radius:${props => props.borderRadius}` };

  ${({borderWidth}) => borderWidth && css`border-width: ${borderWidth}`};
  ${({borderColor}) => borderColor && css`border-color:${theme.colors[borderColor]}` };
  ${({ variant }) => variant && css`border-color: ${({ theme: { colors } }) => colors[`${variant}`]};`}
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: ${props => props.theme.colors[props.focusBrColor]}
  }
  ${({ width }) => width && css`width: ${props => props.width};`}
  ${({ minWidth }) => minWidth && css`min-width: ${props => props.minWidth};`}
  ${({ height }) => height && css`max-height: ${props => props.height};`}
  ${({ caretColor }) => caretColor && css`caret-color: ${theme.colors[caretColor]};`}

  ${({hidden}) => hidden && css`display: none;`}
  ${({ isDateType }) => isDateType && css`
    text-align: start;
    background: ${({ theme: { colors }}) => colors.lightestGreyNew};
    font-size: ${({ theme: { fontSizes },fontSize}) => fontSizes[fontSize ? fontSize : 'md']};
    color: ${({ theme: { colors,color }}) => colors[color ? color : 'darkerGrey']};
    font-family: "ProximaNova-Semibold";
    letter-spacing: ${({ theme: { space }}) => `${space[2]}px;`}
  `}
  
  ${({hideArrows}) =>hideArrows &&
  ` ::-webkit-inner-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
}
::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
} 
}`
}
${({scrollbarWidth}) =>scrollbarWidth &&
  ` ::-webkit-scrollbar {
    width: ${scrollbarWidth};
  }
  ::-webkit-scrollbar-thumb {
  background: #A4B0CC; 
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
}`
}
::-webkit-input-placeholder {
  color: ${props => props.placeholderColor ? props.theme.colors[props.placeholderColor] : props.theme.colors['disabledText']};
}
resize: none;
`

Input.defaultProps = {
  p: '0.8em',
  borderRadius: '4px',
  mb: '0.5em',
  backgroundColor: '#f5f7f9',
  fontSize: 'sm',
  as:'input'
}

export default Input
