import React from "react";
import { FEATURED } from "../featureTagConstants";
import useAppType from "../customHooks/useAppType";
import { useUserDetails } from "../customHooks";

const withIsFeatured = (Component, featureTag) => (props) => {
  const appType = useAppType();
  const { user } = useUserDetails()
  
  if (FEATURED[appType].includes(featureTag) && !user?.has_remote_wallet) return <Component {...props} />;

  return null;
}

export default withIsFeatured;
