import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { media } from '../../utils/mediaBreakpoints';

const RibbonSpan = styled.span`
  position: absolute;
  display: block;
  width: 250px;
  padding: 2px 0;
  background-color: ${props => props.theme.colors.border};
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: ${props => props.theme.colors.blue};
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  ${media.mobile`
    font-size: 6px;
  `}
  font-family: ${({ theme: { fonts }}) => fonts.semiBold};
`

const RibbonTopLeft = styled(Box)`
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  &:after {
    position: absolute;
    z-index: -1;
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent;
    bottom: 0;
    left: 0;
  }
  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent;
    top: 0;
    right: 0;
  }
  ${RibbonSpan} {
    right: 0px;
    top: 16px;
    ${media.mobile`
      top: 10px;
      right: 10px;
    `}
    transform: rotate(-45deg);
  }
`
const Banner = ({ children, className }) => {
  return(
    <RibbonTopLeft className={className}>
      <RibbonSpan>{children}</RibbonSpan>
    </RibbonTopLeft>
  )
} 

export default Banner;


