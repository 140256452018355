import React, { useState, lazy } from 'react';
import Input from './Input'; 
import styled, { css } from 'styled-components';
import format from 'date-fns/format';
const LazyDatePicker = lazy(() => import('./DatePickerLazy'));

const ExtendedInput = styled(Input)`
  color: #4d6189;
  word-spacing: 0px;
  text-align: ${props => props.textAlign };
  ${({disabled}) => disabled && css`
    pointer-events: none;
  `}
`

const CustomDatePicker = ({onChange, dob, placeholder, showYearDropdown, showMonthDropdown, selectedDob, disabled, textAlign, width, color, onChangeKey, minDate, dateFormat='dd-MM-yyyy', height, borderColor}) => {
  const [startDate, setStartDate] = useState(null);
  
  const customChange = (event) => {
    setStartDate(event)
    if (onChangeKey){
      onChange(event)
      return
    }
    const date = format(new Date(event), 'dd-MM-yyyy')
    onChange(date)
  }



  const ExampleCustomInput = ({ value, onClick }) => (
    <ExtendedInput bold disabled={disabled} textAlign={textAlign} color={color} fontSize='md' placeholder={placeholder ?? "DD/MM/YYYY"} id='date-picker' width={width} height={height} value={value} onClick={onClick} borderColor={borderColor} autoComplete="off" />
  );

  return (
    <LazyDatePicker
      selected={startDate}
      onChange={customChange}
      customInput={<ExampleCustomInput />}
      dateFormat={dateFormat}
      maxDate={dob ? (new Date()) : false}
      minDate={minDate}
      value={selectedDob ? selectedDob : startDate}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dropdownMode="select"
    />
  )
} 

CustomDatePicker.defaultProps = {
  showMonthDropdown: true,
  showYearDropdown: true,
  textAlign: 'center',
  width: '100%',
  color: '#a4bocc'
}

export default CustomDatePicker;