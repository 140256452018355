import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { breakpoints } from './mediaBreakpoints';

/**
 * React portals mount the present component outside the DOM hierarchy
 * of the parent component. to overcome elements which has z-index
 * For more refer here: https://reactjs.org/docs/portals.html
 */

const Portals = ({ children, type, visibleOn }) => {
  const toastRoot = document.getElementById('toast-root');
  const modalRoot = document.getElementById('modal-root');
  const drawerRoot = document.getElementById('drawer-root');

  const windowResizeEvent = () => {
    const { mobile, tablet, desktop } = visibleOn;
    if(window.innerWidth <= breakpoints.mobile.max) {
      return !!mobile;
    } else if(window.innerWidth > breakpoints.tablet.min && window.innerWidth < breakpoints.tablet.max) {
      return !!tablet;
    } else {
      return !!desktop;
    }
  }
  const [isModalVisible, updateModalVisibility] = useState(windowResizeEvent())
  useEffect(() => {
    const onWindowResize = () => {
      updateModalVisibility(windowResizeEvent())
    }
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])
  const [element] = useState(document.createElement('div')) // used it this way to persist the element over re-renders.

  useEffect(() => {
    if (type === 'modal' && isModalVisible === false) element.classList.add('display-none');
    return () => {
      element.classList.remove('display-none');
    }
  }, [isModalVisible])
  useEffect(() => {
    if(!isModalVisible) return;
    let node = toastRoot;
    if(type === 'modal') {
      node = modalRoot
      // avoids scrolling of main body on modal load with overflow-hidden class.
      document.body.classList.add('overflow-hidden')
    } else if(type === 'toast') {
      element.classList.add('display-flex__column')
    } else if(type === 'drawer') {
      node = drawerRoot;
      element.classList.add('display-flex__column')
    }
    node.appendChild(element)
    return () => {
      if(type === 'modal') document.body.classList.remove('overflow-hidden');
      if(type === 'toast' || type === 'drawer') element.classList.remove('display-flex__column')
      node.removeChild(element)
    }
  }, [])

  return (
    createPortal(
      children,
      element
    )
  )
}

Portals.defaultProps = {
  visibleOn: {
    mobile: true,
    tablet: true,
    desktop: true
  },
  type: 'toast'
}
export default Portals;