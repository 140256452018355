import request from "../utils/request";
import {
  API_NOTIFICATIONS_VIEW_ALL, API_NOTIFICATIONS, API_READ_ALL_NOTIFICATIONS
} from "../routes/api";

export default class NotificationsAPI {
  static markViewAll = (options, onSuccess, onError) => {
	  return request({...options, url: API_NOTIFICATIONS_VIEW_ALL, method: 'PATCH' }, onSuccess, onError)
  }
  static fetchNotifications = (options, onSuccess, onError) => {
    return request({...options, url: API_NOTIFICATIONS, onSuccess, onError})
  }

  static markNotificationAsRead = ({id, ...options}, onSuccess, onError) => {
    return request({...options, url: `${API_NOTIFICATIONS}/${id}`, method: 'PATCH', onSuccess, onError})
  }

  static markAllNotificationsAsRead = (options, onSuccess, onError) => {
    return request({...options, url: API_READ_ALL_NOTIFICATIONS, method: 'PATCH', onSuccess, onError})
  }
}
