import styled from 'styled-components';
import { Icon } from '../atoms';

const IconWithHoverEffect = styled(Icon).attrs(({ size}) => ({ size: size }))`
  cursor: pointer;
  width: ${({ theme: { iconSizes }, size}) => iconSizes[size]};
  height: ${({ theme: { iconSizes }, size}) => iconSizes[size]};
  margin-left:  ${props => props.ml};
  margin-right:  ${props => props.mr};
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  &:hover {
    background-color: ${({theme}) => theme.colors.lighterGrey};
    border-radius: 50%;
  }
`

IconWithHoverEffect.defaultProps = {
  size: 'lg',
  m: 4
}

export default IconWithHoverEffect;
