import React from 'react'
import { Icon } from '../atoms'
import { instantChat } from '../../assets/icons'
import { useUserDetails } from '../../utils/customHooks'
import { Text } from '../atoms/typography'

const ContactSupportList= () => {
  const { customer_support } = useUserDetails()
  const mobileNumber = customer_support.support_mobile_number
  const supportedMail = customer_support.support_email

  const handleCallClick = () => {
    window.open(`tel:${mobileNumber}`, '_self')
  }

  const handleEmailClick = () => {
    window.open(`mailto:${supportedMail}`, '_self')
  }

  return (   
    <div className='flex border'>
      <div >
        <Icon src={instantChat} size='20px' pl={'16px'} pr={'10px'}my={'16px'}height='40px'/>
      </div>
      <div className="ml-2 m-2">
        <div >
          <p className="text-gray-800 font-pn-semibold">Contact Support</p>
        </div>
        <div className="flex flex-col">
          <Text className='cursor-pointer' onClick={handleCallClick} mt={'12px'} color='extraInformation' lineHeight='0px' fontSize='md'>call us on&nbsp; <span className="font-bold text-blue-500">{mobileNumber}</span></Text>
          <Text className="cursor-pointer "   mt={'24px'} mb={'8px'} onClick={handleEmailClick} color='extraInformation' lineHeight='0px' fontSize='md' >email us at &nbsp; <span className="font-bold text-blue-500">{supportedMail}</span></Text>
        </div>
      </div>
      
    </div>
  )
}

export default ContactSupportList