export const OPEN_SEARCH_PACKAGES_MODAL = "OPEN_SEARCH_PACKAGES_MODAL"
export const CLOSE_SEARCH_PACKAGES_MODAL = "CLOSE_SEARCH_PACKAGES_MODAL"
export const CLEAR_RECENT_PACKAGE_SEARCH = "CLEAR_RECENT_PACKAGE_SEARCH"
export const SEARCH_PACKAGES = "SEARCH_PACKAGES"
export const SET_PACKAGES = "SET_PACKAGES"
export const OPEN_FAMILY_MEMBER_MODAL = "OPEN_FAMILY_MEMBER_MODAL"
export const CLOSE_FAMILY_MEMBER_MODAL = "CLOSE_FAMILY_MEMBER_MODAL"
export const SELECT_FAMILY_MEMBER = "SELECT_FAMILY_MEMBER"
export const SHOW_ADD_FAMILY_MEMBER_MODAL = "SHOW_ADD_FAMILY_MEMBER_MODAL"
export const CLOSE_ADD_FAMILY_MEMBER_MODAL = "CLOSE_ADD_FAMILY_MEMBER_MODAL"
export const SET_CUSTOMER = "SET_CUSTOMER"
export const SET_MAIN_CUSTOMER = "SET_MAIN_CUSTOMER"
export const ADD_PACKAGES_AND_CLOSE_MODAL = "ADD_PACKAGES_AND_CLOSE_MODAL"
export const RELOAD_FAMILY_MEMBERS = "RELOAD_FAMILY_MEMBERS"
export const REMOVE_PACKAGE = "REMOVE_PACKAGE"
export const RESET_MODALS = "RESET_MODALS"
export const ADD_PACKAGE = "ADD_PACKAGE"
export const OPEN_FAQ_MODAL = 'OPEN_FAQ_MODAL'
export const CLOSE_FAQ_MODAL = 'CLOSE_FAQ_MODAL'