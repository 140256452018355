import React from 'react';
import tracker from '../tracker';

const withTrackerHOC = (Component) => (props) => {
  const onClick = (event) => {
    const eventName = event.target?.dataset?.eventName
    let eventData = event.target?.dataset?.eventData
    if(eventName && window.Moengage) {
      if(eventData) {
        eventData = JSON.parse(eventData)
        tracker.track(eventName, eventData)
      } else {
        tracker.track(eventName)
      }
    }
  }
  return (
    <div onClick={onClick}>
      <Component {...props}/>
    </div>
  )
}

export default withTrackerHOC;