import { API_USER_LOGIN, API_USER_LOGOUT, WEBVIEW_API_USER_LOGOUT } from '../routes/api';

export const initiateAxiosObject = ({ headers, options, ...rest }) => {
  {
    if (options.url === API_USER_LOGIN && window.isEkincareApp()) {
      options.url = '/v2/customers/auth_new';
    }

    if (options.url === API_USER_LOGOUT) {
      options.url = WEBVIEW_API_USER_LOGOUT
      options.data = { token: window.localStorage.getItem('PUSH-TOKEN') }
    }

    let axiosOptions = {
      ...rest,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8', 'accept': 'application/json',
        'x-ekincare-key': window.localStorage.getItem('EKINCARE_KEY'),
        'x-customer-key': window.localStorage.getItem('CUSTOMER_KEY'),
        'x-device-id': window.channelId(),
        'Access-Control-Allow-Origin': '*',
        ...headers
      }
    }

    if (process.env.REACT_APP_API_URL) {
      axiosOptions.baseURL = process.env.REACT_APP_API_URL
    }

    return axiosOptions
  }

}
