import { Link } from 'react-router-dom';
import styled, {css} from 'styled-components';

const RouteLink = styled(Link)`
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
	${p => p.mt && css`margin-top: ${p.mt}`};
	${p => p.lineHeight && css` line-height: ${p.lineHeight}`};
  font-family: ${({ bolder, bold, theme: { fonts }}) => {
	  if(bolder) {
	    return fonts.extraBold
  } else if(bold) {
    return fonts.semiBold
  } else {
    return fonts.normal
  }
}};
  text-decoration-line: none!important;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
	font-size: ${({ theme: { fontSizes }, size}) => fontSizes[size]};
	${({ theme: { links }, variant, disabled, display, isAnimated}) => css`
    color: ${links[variant].color};
    &:hover {
			${!disabled && css`
					color: ${links[variant].hoverColor};
			`}
    }
    ${disabled && css`
			cursor: unset;
    `}
    ${display && css`
      display: ${display};
    `}
    ${isAnimated && css`
			position: relative;
			&::after {
				content: '';
				height: 2px;
				position: absolute;
				background-color: ${({ theme: { colors }}) => colors.blue};
				width: 100%;
				left: 0px;
				bottom: 0px;
				transform: scaleX(0);
				transform-origin: right;
				transition: transform 250ms ease-in;
			}
			&:hover::after {
				transform: scaleX(1);
				transform-origin: left;
			}
		`}
	`}
`
RouteLink.defaultProps = {
  variant: 'primary',
  size: 'sm'
}

export default RouteLink;