import styled from 'styled-components';
import { Text } from './typography';

const Heading = styled(Text).attrs(({ theme: { space, fontSizes } , size = 'lg' }) => {
  let configure = {}
  switch (size) {
  case 'xl': {
    configure = {
      margin: `${space[6]/16}em`, // 2em margin
      padding: `${space[6]/16}em`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  } case 'lg': {
    configure = {
      margin: `${space[5]/16}em`, // 1em margin
      padding: `${space[5]/16}em`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  } case 'md': {
    configure = {
      margin: `${space[4]/16}em`, // 0.5em margin
      padding: `${space[4]/16}em`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  } case 'sm': {
    configure = {
      margin: `${space[3]/16}em`, // 0.25em margin
      padding: `${space[3]/16}em`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  } case 'xs': {
    configure = {
      margin: `${space[2]/16}em`, // 0.125em margin
      padding: `${space[2]/16}em`,
      fontSize: `${fontSizes[size]}`
    }
    break;
  }
  }
  return {
    ...configure
  }
})`
  font-size: ${({ fontSize }) => fontSize};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  color: ${({theme: { colors }}) => colors.extraInformation};
  text-transform: capitalize;
  white-space: nowrap;
  font-family: ${({theme: { fonts }}) => fonts.extraBold};
`

export default Heading;