import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { notFound } from '../../assets/images';
import EmptyPage from '../EmptyPage';
import { excludeMedia } from '../../utils/mediaBreakpoints';
import { HOME } from '../../routes/private';

const ErrorContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  ${excludeMedia.mobile`
    margin-top: ${({ theme: { space }}) => `${space[7]}px`}
  `}
`
const NotFoundPage = () => (
  <ErrorContainer>
    <EmptyPage
      image={{src: notFound, width: '30%'}}
      header={{text: 'Page not found!'}}
      body={{text: 'Page you are looking for does not found'}}
      clickToAction={{text: 'Take me to Home Page', to: HOME}}
    />
  </ErrorContainer>
)

export default NotFoundPage;