import React from 'react'
import {Icon} from '../../atoms'
import {Flex, Box} from 'rebass'
import {backBlack} from '../../../assets/icons'
import styled, {css} from 'styled-components'
import {media} from '../../../utils/mediaBreakpoints';
import { useHistory } from 'react-router-dom';
import { Text } from '../../atoms/typography';
import { isMobile } from '../../../utils/common';

const HoverBox = styled(Box)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.lighterGrey};
    border-radius: 50%; 
  }
  cursor: pointer;
  width: 35px;
  height: 35px;
  padding: 5px;
  ${media.desktop`
    margin-left: ${({ ml }) => ml ? ml : '16px'};
  `}
  ${({modal}) => modal && css`
    margin-left: 16px;
  `}
`

const PageHeaderFlex = styled(Flex)`
  /* padding-top: 10px; */
  ${media.mobile`
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: ${({ theme: { colors }}) => colors.whiteColor};
    width: 100%;
    padding-left: ${({ pl }) => pl ? pl : '10px'};
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${p => p.theme.borders.borderGrey};
  `}
  min-height: 60px;
  height: ${({ height }) => height ? height : '60px'};


  ${media.desktop`
    border: ${({modal, backgroundColor}) => modal ? `1px solid  ${backgroundColor}` : 'none'};
    background-color: ${({modal}) => modal ? 'white' : 'inherit'};
  `}
`


const PageHeader = ({ title, onBack: goBack, pl, modal, backgroundColor, iconNeeded, fontSize, height }) => {
  const history = useHistory()
  return(
    <PageHeaderFlex alignItems='center' height={height} modal={modal} flexDirection='row' backgroundColor={backgroundColor} pl={pl}>
      {iconNeeded && <HoverBox modal={modal} borderRadius="50%">
        <Icon src={backBlack} onClick={goBack || history.goBack} size='md' />
      </HoverBox>}
      <Text ml="12px" fontSize={fontSize} color='title' bolder={!isMobile()} bold={isMobile()}>{title}</Text>
    </PageHeaderFlex> 
  )
}

PageHeader.defaultProps = {
  backgroundColor: 'white',
  modal: false,
  iconNeeded: true,
  fontSize: 'lg'
}


export default PageHeader