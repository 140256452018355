import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { TitleWithSubTitle, MultiSelectOptions, ErrorLabel } from '.';

const TitleSubTitleWithMultiSelectionsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: ${({ alignItems }) => alignItems};
`

const TitleSubTitleWithMultiSelections = ({ title, subTitle, alignItems, onClick, list, errors, ...props }) => (
  <TitleSubTitleWithMultiSelectionsContainer alignItems={alignItems} {...props}>
    <TitleWithSubTitle title={title} subTitle={subTitle} />
    <MultiSelectOptions options={list} onClick={onClick}/>
    <ErrorLabel>{errors.join(', ')}</ErrorLabel>
  </TitleSubTitleWithMultiSelectionsContainer>
)

TitleSubTitleWithMultiSelections.defaultProps = {
  alignItems: 'flex-start',
  title: null,
  subTitle: null,
  list: [],
  errors: []
}

export default TitleSubTitleWithMultiSelections;