import styled from 'styled-components';

const SectionText = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.body};
  font-size: 0.938em;
  font-family: ${({ theme: { fonts }}) => fonts.normal};
  line-height: 1.5;
  letter-spacing: 0.01071em;
  display: block;
  b {
    font-family: ${({ theme: { fonts }}) => fonts.semiBold};
  }
`

export default SectionText