import React from 'react';
import { Icon , Card} from '../atoms';
import styled from 'styled-components';
import { Flex } from 'rebass';
import {closeSearch} from '../../assets/icons';
import {ModalDrawer, Modal} from '../molecules';
import {PageHeader} from '../molecules/buildingblocks';

const IconExtended = styled(Icon)`
  cursor: pointer;
  position: absolute;
  background-color: none;
`
const Direction = styled(Flex)`
  direction: rtl;
`

const BasicModal = ({onClick, Component, direction, modalType, pageTitle, overLayBackgroundColor, top, iconTop, zIndex}) => {
  return (
    <>
      {modalType == 'modal' ? 
        <Modal style={{zIndex: 200}} size='xs' top={top} minHeight={{mobile: '10vh', tablet: '20vh', desktop: '10vh'}} direction={direction} closeModal={() => onClick(false)}>  
          <Card>
            <Direction pr={4} mr={3} alignItems='center' >
              <IconExtended style={{top: iconTop ? iconTop : ''}} src={closeSearch} onClick={() => onClick(false)} size='xxl'/>
            </Direction>
            {Component}
          </Card>
        </Modal> :
        <ModalDrawer open={true} overLayBackgroundColor={overLayBackgroundColor} closeDrawer={onClick} backgroundColor='lightstGreNew' closeOnClickOverlay={onClick} zIndex={zIndex}>
          <Card py={[0, 4]} flexDirection='column' borderRequired={false}>
            <PageHeader
              title={pageTitle}
              onBack={onClick}  
              modal={true}
            />
          </Card>
          {Component}
        </ModalDrawer> }
    </>
  )
}
BasicModal.defaultProps = {
  direction: 'top-bottom',
  modalType: 'modal',
  overLayBackgroundColor: true,
  top: "5%"
}
export default BasicModal;