import React, { useState, memo, useEffect } from 'react';
import NavbarItem from '../../molecules/NavbarItem';
import TopMenu from '../../molecules/TopMenu';
import home_grey from '../../../assets/icons/home-grey.svg';
import home_blue from '../../../assets/icons/home-blue.svg';
import benefits_blue from '../../../assets/icons/benefits-blue.svg';
import benefits_grey from '../../../assets/icons/benefits-grey.svg';
import health_blue from '../../../assets/icons/health-blue.svg';
import health_grey from '../../../assets/icons/health-grey.svg';
import improve_blue from '../../../assets/icons/improve-blue.svg';
import improve_grey from '../../../assets/icons/improve-grey.svg';
import { notifications as notificationsGrey, notificationsBlue } from '../../../assets/icons'
import {
  DASHBOARD, NOTIFICATIONS, BENEFITS,HEALTH_PAGE, IMPROVE_PAGE
} from '../../../routes/private';
import useUserDetails from '../../../utils/customHooks/useUserDetails';
import useFetchNotifications from '../../../utils/customHooks/useFetchNotifications';
import useAppType from '../../../utils/customHooks/useAppType';


const excludedIcons = {
  home: { inactive: home_grey, active: home_blue, link: DASHBOARD },
  benefits: {
    inactive: benefits_grey, active: benefits_blue, link: BENEFITS
  },
  health: { inactive: health_grey, active: health_blue, link: HEALTH_PAGE },
  improve: { inactive: improve_grey, active: improve_blue, link: IMPROVE_PAGE },
  notifications: { inactive: notificationsGrey, active: notificationsBlue, link: NOTIFICATIONS }
}

const AnimatedNavbar = ({ history }) => {
  const [icons, updateIcons] = useState({ home: excludedIcons['home'].inactive, improve: excludedIcons['improve'].inactive, health: excludedIcons['health'].inactive });
  const [animationState, updateAnimationState] = useState({
    activeIndices: {},
    animatingOut: false
  });
  const { markAllViewed } = useFetchNotifications()
  const titles = {
    home: { title: 'Home', link: DASHBOARD },
    improve: { title: 'Improve', link: IMPROVE_PAGE },
    health: { title: 'Health', link: HEALTH_PAGE }
  }
  const appType = useAppType()
  const { user } = useUserDetails()

  const navbarConfig = [
    {
      uniqueId: 'health',
      title: 'Health',
    },
    ...((appType !== 'PWA' && !user.has_remote_wallet) ? [
      {
        uniqueId: 'improve',
        title: 'Improve',
      }
    ] : []),
  ];

  useEffect(() => {
    if (
      animationState.activeIndices.prevIndex &&
      navbarConfig[animationState.activeIndices.prevIndex]) {
      markAllViewed()
    }
  }, [animationState.activeIndices.prevIndex])


  const reapplyDropdownState = (currentIndex) => {
    updateAnimationState({
      ...animationState,
      activeIndices: {
        currentIndex,
        prevIndex: animationState.activeIndices.currentIndex
      },
      animatingOut: false
    });
  };

  const onMouseEnter = i => {
    const {
      activeIndices: { currentIndex }
    } = animationState;

    if (i !== undefined && currentIndex === i) return;
    if (typeof i === 'number') {
      updateAnimationState({
        ...animationState,
        animatingOut: true
      })
      reapplyDropdownState(i)
    }
  };

  const onMouseLeave = () => {
    updateAnimationState({
      ...animationState,
      animatingOut: true
    })
    // Giving sometime for animation to get completed before reseting dropdownstate.
    window.setTimeout(reapplyDropdownState, 500)
  };

  const mouseEnter = (feature) => {
    reapplyDropdownState()
    updateIcons({ ...icons, [feature]: excludedIcons[feature].active })
  };

  const mouseLeave = (feature) => {
    reapplyDropdownState()
    updateIcons({ ...icons, [feature]: excludedIcons[feature].inactive })
  };

  const {
    activeIndices: { currentIndex }
  } = animationState;

  const isCurrentRoute = (feature) => {
    if (['home'].includes(feature)) {
      return history.location.pathname === excludedIcons[feature].link
    } else {
      return (excludedIcons[feature].link)?.includes(history.location.pathname)
    }
  }

  return (
    <TopMenu onMouseLeave={onMouseLeave}>
      <NavbarItem
        onMouseEnter={() => mouseEnter('home')}
        onMouseLeave={() => mouseLeave('home')}
        link={{ to: DASHBOARD }}
        title="Home"
        icon={{ src: [DASHBOARD, '/'].includes(history.location.pathname) ? excludedIcons['home'].active : icons['home'] }}
      />
      {navbarConfig.map((n, index) => {
        return (
          <NavbarItem
            title={n.title}
            index={index}
            icon={isCurrentRoute(n.uniqueId) ? { src: excludedIcons[n.uniqueId].active } : (currentIndex === index ? { src: excludedIcons[n.uniqueId].active } : { src: excludedIcons[n.uniqueId].inactive })}
            onMouseEnter={onMouseEnter}
            key={index}
            link={{ to: titles[n.uniqueId].link}}
          />
        );
      })}
    </TopMenu>
  );
};

export default memo(AnimatedNavbar);