import { LOGIN, PUBLIC_ROUTES } from '../routes/public';
import toast from '../utils/toast';
import { isInsuranceRole } from '../utils/common';

// TODO:: Add in custom messages across application to maintain consistancy around error messages.
const errorDecorator = (error) => {
  if(error.response && error.response.status === 401) {
    if(window.history && window.history.state) {
      const paths = PUBLIC_ROUTES.map(route => route.path)
      if(paths.indexOf(window.location.pathname) === -1){
        toast.error('Your session has expired. Please login again to continue.', {code: 401})
      }
      // Check for history.state here: https://developer.mozilla.org/en-US/docs/Web/API/History/state
    }
    setTimeout(() => {
      if(!isInsuranceRole && window.location.pathname != LOGIN)
        window.location.pathname = LOGIN
    }, 2000)
  }
  return error;
}

export default errorDecorator;