import React from 'react'
import { improveBlue, improveGrey } from '../../../assets/icons'
import { IMPROVE_PAGE } from '../../../routes/private'
import { RouterNavLink } from '../../../utils/common.styles'
import { FEATURE_TAGS } from '../../../utils/featureTagConstants'
import withIsFeatured from '../../../utils/hocs/withIsFeatured'
import { IconWithText } from '../../molecules'
import { Ripple, Text } from '../../atoms/typography'

const ImproveBottomBarTab = ({ pathname }) => {
  const isImprovePath = pathname === IMPROVE_PAGE;
  return (
    <Ripple>
      <RouterNavLink to={IMPROVE_PAGE}>
        <IconWithText mt={1} src={isImprovePath ? improveBlue : improveGrey} size="md">
          <Text color={isImprovePath ? 'blue' : 'subtitle'} fontSize="sm" lineHeight="12px" mt={3} bold={isImprovePath}>Improve</Text>
        </IconWithText>
      </RouterNavLink>
    </Ripple>
  )
}

export default withIsFeatured(ImproveBottomBarTab, FEATURE_TAGS.IMPROVETAB)
